
/* eslint-disable */
declare var window: any;
// declare function goYouTube(link: string): void;
declare function initYouTube(id: string): void;
declare var YT: any;

import { defineComponent } from 'vue'
import {
  Quiz,
  Subject,
  Module,
  WebinarQuiz,
  PrivateWebinar,
  PackageTopup,
} from '@/dto'
import ClassroomLevel from '@/dto/ClassroomLevel'
import Classroom from '@/dto/Classroom'

import ClassroomModel from '@/models/ClassroomModel'
import QuizModel from '@/models/QuizModel'
import VoucherModel from '@/models/VoucherModel'
import WebinarQuizModel from '@/models/WebinarQuizModel'
import TransactionModel from '@/models/TransactionModel'

import Webinar from '@/main/components/Cards/Webinar.vue'
import ModalPack from '@/main/components/Modals/ModalPack.vue'
import ModalRequestWebinar from '@/main/components/Modals/ModalRequestWebinar.vue'
import ModalQuiz from '@/main/components/Modals/ModalQuiz.vue'
import ModalKuis from '@/main/components/Modals/ModalKuis.vue'
import ModalResultQuiz from '@/main/components/Modals/ModalResultQuiz.vue'
import ModalResultPreTest from '@/main/components/Modals/ModalResultPreTest.vue'
import ModalResultPostTest from '@/main/components/Modals/ModalResultPostTest.vue'
import CardWebinar from '@/main/components/Cards/CardWebinar.vue'
import ModalStatus from '@/main/components/Modals/ModalStatus.vue'
import Slider from '@vueform/slider'
import CardPrivateWebinar from '@/main/components/Cards/CardPrivateWebinar.vue'
import Loading from '@/main/components/Loading.vue'

import { formatDuration } from '@/utils/Util'
import axios from 'axios'
import moment from 'moment'
import Cookies from 'js-cookie'
import 'fix-date'
import { useToast } from 'vue-toastification'
import { dragscrollNext } from 'vue-dragscroll'

export default defineComponent({
  directives: {
    dragscroll: dragscrollNext,
  },
  setup() {
    const toast = useToast()
    return {
      toast,
    }
  },
  data() {
    return {
      classroom: {} as Classroom,
      classroomLevel: {} as ClassroomLevel,
      classroomCode: '',
      classroomLevelCode: '',
      listWebinar: null as any,
      tab: '' as any,
      quiz: [] as Quiz[],
      modul: [] as Module[],
      subjects: null as Subject[] | null,
      isLoading: true as Boolean,
      webinarRecord: [] as any,
      isLoadingMaterial: true,
      isLoadingQuiz: true,
      isLoadingWebinar: true,
      isLoadingWebinarRecord: true,
      isLoadingSubmitQuiz: false,
      isCountdown: this.$route.query.is_countdown,
      isTab: this.$route.query.tab as any,
      levelData: {} as ClassroomLevel,
      dateNow: moment().format('YYYY-MM-DD HH:mm:ss'),
      selectedModule: null as any,
      subjectSelected: {} as any,
      selectedWebinarRecord: null as any,
      preTest: [] as any,
      postTest: [] as any,
      displayDays: 0,
      displayHours: 0,
      displayMinutes: 0,
      displaySeconds: 0,
      distanceDay: true,
      isStartedYet: false,
      isProcessed: false,
      isNoAccomplishement: false,
      options: {
        clickToPlay: true,
        keyboard: {
          focused: false,
          global: false,
        },
        disableContextMenu: false,
        quality: {
          default: 480,
          // The options to display in the UI, if available for the source media
          options: [1080, 720, 480, 360],
          forced: true,
        },
        settings: ['quality'],
        controls: [
          'play-large',
          'play',
          'progress',
          'current-time',
          'mute',
          'volume',
          'settings',
          'airplay',
          'fullscreen',
        ],
      },
      modalPackStatusError: false,
      imageUrlModal: '',
      firstTextModal: '',
      secondTextModal: '',
      firstButtonModal: '',
      secondButtonModal: '',
      uploadPercentage: 0,
      showUploadProgress: false,
      tasks: [] as any,
      modalWebinarQuiz: false,
      textModalWebinarQuiz: '',
      webinarQuiz: {} as WebinarQuiz,
      isDonePreTest: true,
      isExistPostTest: false,
      isDonePostTest: true,
      isAttemptPostTest: false,
      isAutoPlay: true,
      isPrakerja: false,
      postTestClass: {} as any,
      modalRequestWebinarPlus: false,
      payloadRequestWebinarPlus: {
        id: 0 as number,
        reason: '' as string,
        classroom: '' as string,
      },
      errorRequestWebinarPlus: false,
      showWebinarPlusReason: false,
      modalPostTest: false,
      textModalPostTest: '',
      modalExam: false,
      textModalExam: '',
      isDoneQuiz: false,
      selectedExam: {} as any,
      selectedMaterial: {} as any,
      player: null as any,
      iframe: null as any,
      showWatermark: true,
      duration: 0,
      currentTime: 0,
      percentage: 0,
      isPlayed: false,
      processEnded: false,
      minuteText: '',
      secondText: '',
      theInterval: null as any,
      selectedMaterialItem: {} as any,
      materials: [] as any,
      selectedQuiz: {} as any,
      toggledMaterial: [] as any,
      isExistMaterial: false,
      countMateri: 0 as number,
      countProgress: 0 as number,
      countDoneProgress: 0 as number,
      isDoneMateri: false,
      errorAskMentor: false,
      listUserQuestion: [] as any,
      loadingUserQuestion: false,
      loadingSubmitQuestion: false,
      userQuestion: '' as string,
      formQuestion: {
        question: '' as string,
        file: '' as string,
      },
      assets: [] as any,
      isExistResolution: false,
      resolutionVideos: [] as any,
      isYoutube: false,
      youtubeId: '' as string,
      modalPackStatusMateri: false,
      quizId: 0 as number,
      quizData: {} as Quiz,
      payloadQuiz: {
        answers: [] as any,
        item_ids: [] as any,
      },
      questionIndex: 0 as number,
      showQuiz: false,
      displayResult: false,
      result: {
        quizName: '' as any,
        countTrue: 0 as Number,
        countFalse: 0 as Number,
        score: 0 as Number,
        minScore: 0 as Number,
      },
      isExpandQuestion: true,
      showModalStartQuiz: false,
      showModalConfirmQuiz: false,
      showModalNotDoneQuiz: false,
      modalQuiz: {
        title: '' as String,
        textOne: '' as String,
        textTwo: '' as String,
        imageUrl: '' as String,
      },
      showAlertQuiz: false,
      tempMaterial: {} as any,
      tempMaterialItem: {} as any,
      redirect: '' as string,
      prePostTestData: {} as any,
      showPrePost: false,
      showModalStartPreTest: false,
      showModalStartPostTest: false,
      showModalConfirmPrePostTest: false,
      testType: 0, // 0 = pre test, 1 = post test
      displayResultPreTest: false,
      displayResultPostTest: false,
      showPostTest: false,
      isExistPreTest: false,
      showReview: false,
      showAlertReview: true,
      tempRating: null as any,
      orig: null as any,
      formReview: {
        rating: null as any,
        content: '' as string,
        imageRating: null as any,
      },
      loadingReview: false as boolean,
      previewImageRating: null as any,
      showModalImageRating: false,
      updatedImageRating: null as any,
      loadingCropImage: false as boolean,
      showWebinarList: true,
      showWebinarRecordList: false,
      filterWebinar: 'waiting',
      showAlert: false,
      modalAlert: {
        status: '' as string,
        title: '' as string,
        description: '' as string,
      },
      payloadWebinarQuiz: {
        answers: [] as any,
        item_ids: [] as any,
      },
      webinarQuizData: {} as WebinarQuiz,
      showWebinarQuiz: false,
      showModalConfirmWebinarQuiz: false,
      isDoneWebinarQuiz: false,
      tempTab: '' as any,
      showAlertTab: false,
      showWebinar: false,
      showWebinarRecord: false,
      showModalAskMentor: false,
      selectedTask: {} as any,
      showModalTask: false,
      typeFile: 'berkas',
      formTask: {
        file: null as any,
        note: '' as string,
      },
      errorTaskLinkName: false,
      showTaskScore: false,
      listRequestWebinarPlus: [] as any,
      isVideo: this.$route.query.video as any,
      isModule: this.$route.query.module as any,
      isVideoDone: true,
      isModuleDone: true,
      isQuizDone: true,
      isWebinarDone: true,
      isSubmissionDone: true,
      isExamDone: true,
      isAlreadyRated: false,
      privateWebinar: [] as any,
      showModalAddQuota: false,
      listQuotaPrice: {} as any,
      loadingAddQuota: false,
      formAddQuota: {
        amount: 1 as number,
        payment_code: '' as string,
        classroom_code: '' as string,
      },
      displayPaymentQuota: false,
      showModalPrivateWebinar: false,
      filterPrivateWebinar: 'requested' as string,
      formPrivateWebinar: {
        material: '' as string,
        note: '' as string,
        mentor: '' as string,
        request_started_at: '' as string,
      },
      loadingAddPrivateWebinar: false,
      showAlertPrivateWebinar: false,
      isReapplyPrivateWebinar: false,
      selectedPrivateWebinar: {} as PrivateWebinar,
      formReapplyPrivateWebinar: {
        request_started_at: '' as string,
      },
      packageTopup: [] as PackageTopup[],
      selectedPackage: {} as PackageTopup,
      usePackageTopup: false,
      isExistForum: true,
      listPaymentManual: [
        {
          type: 'E-Wallet',
          note:
            'Kamu bisa melakukan transaksi melalui e-wallet yang kamu miliki.',
          payment: [
            {
              account: 'OVO',
              code: 'ovo',
              icon: '/assets/images/payment/logo_ovo.png',
              id: 22,
              instructions: '',
              name: 'OVO',
            },
            {
              account: 'Dana',
              code: 'dana',
              icon: '/assets/images/payment/logo_dana.png',
              id: 23,
              instructions: '',
              name: 'DANA',
            },
            {
              account: 'Gopay',
              code: 'gopay',
              icon: '/assets/images/payment/logo_gopay.png',
              id: 24,
              instructions: '',
              name: 'GOPAY',
            },
            {
              account: 'Link Aja',
              code: 'link-aja',
              icon: '/assets/images/payment/logo_linkaja.png',
              id: 25,
              instructions: '',
              name: 'Link Aja',
            },
            {
              account: 'Shopee Pay',
              code: 'shopeepay',
              icon: '/assets/images/payment/logo_shopeepay.png',
              id: 26,
              instructions: '',
              name: 'Shopee Pay',
            },
          ],
        },
        {
          type: 'Transfer Bank',
          note: 'Kode unik transaksi untuk mempercepat proses verifikasi.',
          payment: [
            {
              account: '1380556667777',
              code: 'mandiritf',
              icon: '/assets/images/payment/logo_mandiri.png',
              id: 19,
              instructions:
                '1. Masukkan kartu ke mesin ATM, pilihlah bahasa masukan kemudian masukkan kode PIN ATM Anda dengan benar.\\n2. Pilihlah menu “Transaksi Lainnya” pada laman menu.\\n3. Pilihlah “Transfer” , lalu pilih “Rekening Mandiri”.\\n4. Masukan Nomor $1 a.n. $3 kemudian pilih Benar.\\n5. Masukan jumlah nominal uang $2 kemudian pilih Benar.\\n6. Mohon periksa kembali informasi nomor rekening tujuan dan nominal uang yang akan ditransfer.\\n7. Kemudian, selembar struk akan keluar dari ATM. Jangan lupa untuk mengunggah bukti struk transaksi ini untuk menyelesaikan transaksi.',
              name: 'Bank Mandiri',
            },
            {
              account: '3948000999',
              code: 'bcatf',
              icon: '/assets/images/payment/logo_bca.png',
              id: 20,
              instructions:
                '1. Masukkan kartu ke mesin ATM, pilihlah bahasa masukan kemudian masukkan kode PIN ATM Anda dengan benar.\\n2. Pilihlah menu “Transfer” dan “Ke Rek BCA”.\\n3. Kemudian, masukkan nomor rekening BCA yang dituju $1 a.n. $3\\n4. Setelah itu, lanjutkan dengan memasukkan nominal sebesar $2.\\n5. Lalu, ada pertanyaan tentang No Referensi. Bagian ini bisa dikosongkan.\\n6. Tekan OK atau Ya.\\n7. Kemudian, selembar struk akan keluar dari ATM. Jangan lupa untuk mengunggah bukti struk transaksi ini untuk menyelesaikan transaksi.',
              name: 'Bank BCA',
            },
          ],
        },
      ] as any,
    }
  },

  async created() {
    // document.addEventListener('beforeunload', ()=> {
    //   window.confirm('test')
    // });
    if (Object.keys(this.getProfileData)?.length == 0) {
      this.getProfile()
    }
    const params: any = this.$route.params
    this.classroomCode = params.code
    this.classroomLevelCode = params.code + '1'
    this.isLoading = true
    await this.getClassRoomLevelDetail(this.classroomLevelCode)
    if (!this.isTab) {
      this.tab = 'materi'
    } else {
      this.tab = this.isTab
    }
    await this.init()
  },

  async mounted() {
    // this.mountYoutubeApi()
  },

  computed: {
    _seconds() {
      return 1000
    },
    _minutes() {
      return 1000 * 60
    },
    _hours() {
      return 60000 * 60
    },
    _days() {
      return 3600000 * 24
    },
    onPlayerReady(e: any): void {
      this.methodOnPlayerReady(e)
    },
    onPlayerStateChange(e: any): void {
      console.log('ganti state')
      this.methodOnPlayerStateChange(e)
    },
    getProfileData(): any {
      return this.$store.getters['profileStore/getProfileData']
    },
    checkFormReview(): Boolean {
      if (this.formReview.rating > 0 && this.formReview.content != '') {
        return true
      } else {
        return false
      }
    },
    checkFormQuestion(): Boolean {
      if (this.formQuestion.question) {
        return true
      } else {
        return false
      }
    },
    countWebinarWaiting(): number {
      const {
        'classroomStore/getClassroomWebinarData': getClassroomWebinarData,
      } = this.$store.getters as any
      if (getClassroomWebinarData?.length > 0) {
        return getClassroomWebinarData.filter((el: any) => {
          if (this.timeZone(el.started_at) > this.dateNow) {
            return el
          }
        }).length
      } else {
        return 0
      }
    },
    countWebinarOnGoing(): number {
      const {
        'classroomStore/getClassroomWebinarData': getClassroomWebinarData,
      } = this.$store.getters as any
      if (getClassroomWebinarData?.length > 0) {
        return getClassroomWebinarData.filter((el: any) => {
          if (
            this.timeZone(el.started_at) <= this.dateNow &&
            this.timeZone(el.expired_at) >= this.dateNow
          ) {
            return el
          }
        }).length
      } else {
        return 0
      }
    },
    countWebinarDone(): number {
      const {
        'classroomStore/getClassroomWebinarData': getClassroomWebinarData,
      } = this.$store.getters as any
      if (getClassroomWebinarData?.length > 0) {
        return getClassroomWebinarData.filter((el: any) => {
          if (this.timeZone(el.expired_at) < this.dateNow) {
            return el
          }
        }).length
      } else {
        return 0
      }
    },

    countPrivateWebinarRequested(): number {
      const {
        'classroomStore/getClassroomPrivateWebinarData': getClassroomPrivateWebinarData,
      } = this.$store.getters as any
      if (getClassroomPrivateWebinarData?.length > 0) {
        return getClassroomPrivateWebinarData.filter((el: any) => {
          if (el.status == 0) {
            return el
          }
        }).length
      } else {
        return 0
      }
    },

    countPrivateWebinarApproved(): number {
      const {
        'classroomStore/getClassroomPrivateWebinarData': getClassroomPrivateWebinarData,
      } = this.$store.getters as any
      if (getClassroomPrivateWebinarData?.length > 0) {
        return getClassroomPrivateWebinarData.filter((el: any) => {
          if (el.status == 1) {
            return el
          }
        }).length
      } else {
        return 0
      }
    },

    countPrivateWebinarRejected(): number {
      const {
        'classroomStore/getClassroomPrivateWebinarData': getClassroomPrivateWebinarData,
      } = this.$store.getters as any
      if (getClassroomPrivateWebinarData?.length > 0) {
        return getClassroomPrivateWebinarData.filter((el: any) => {
          if (el.status == -1) {
            return el
          }
        }).length
      } else {
        return 0
      }
    },

    countPrivateWebinarAll(): number {
      const {
        'classroomStore/getClassroomPrivateWebinarData': getClassroomPrivateWebinarData,
      } = this.$store.getters as any
      if (getClassroomPrivateWebinarData?.length > 0) {
        return getClassroomPrivateWebinarData.length
      } else {
        return 0
      }
    },

    checkFormTask(): boolean {
      if (this.typeFile == 'berkas') {
        if (this.formTask?.file) {
          return true
        } else {
          return false
        }
      } else {
        if (this.formTask?.file?.includes('https://')) {
          return true
        } else {
          return false
        }
      }
    },

    checkFormWebinarPlus(): boolean {
      if (
        this.payloadRequestWebinarPlus.id &&
        this.payloadRequestWebinarPlus.reason
      ) {
        return true
      } else {
        return false
      }
    },

    isDiscordLink(): boolean {
      if (this.classroomLevel.forum.includes('discord')) {
        return true
      } else {
        return false
      }
    },

    disabledMinQuota(): boolean {
      if (this.formAddQuota.amount < 2) {
        return true
      } else {
        return false
      }
    },

    disabledPlusQuota(): boolean {
      if (this.formAddQuota.amount >= 20) {
        return true
      } else {
        return false
      }
    },

    checkPaymentCodeSelected(): boolean {
      if (this.formAddQuota.payment_code == '') {
        return false
      } else {
        return true
      }
    },

    checkFormAddPrivateWebinar(): boolean {
      if (
        this.formPrivateWebinar.request_started_at != '' &&
        this.formPrivateWebinar.note != '' &&
        this.formPrivateWebinar.material != '' &&
        this.formPrivateWebinar.mentor != ''
      ) {
        return true
      } else {
        return false
      }
    },
  },

  methods: {
    async getProfile() {
      await this.$store.dispatch('profileStore/fetchProfileData')
    },
    zeroPad(num: any, places: any): string {
      var zero = places - num.toString().length + 1;
      return Array(+(zero > 0 && zero)).join("0") + num;
    },
    playInterval() {

      if( this.player ) {
        this.duration = this.player.getDuration()
        this.currentTime = this.player.getCurrentTime()
      }

      if( this.currentTime > 0 ) {
          this.percentage = Math.round(this.currentTime / this.duration * 100)
          // console.log(percentage)
          const minute = Math.floor(this.currentTime / 60)
          const second = Math.floor(this.currentTime % 60)

          this.minuteText = this.zeroPad(minute, 2)
          this.secondText = this.zeroPad(second, 2)

          if( this.percentage >= 100 ) {
            clearInterval(this.theInterval)
          }

          if( this.duration - this.currentTime <= 5 && !this.processEnded ) {
            this.processEnded = true
            this.player.destroy()
            this.showWatermark = false
            this.onEndVideo()
            this.isPlayed = false
          }
      }
    },
    playFullScreen(): void {
      this.iframe = this.player.getIframe()
      console.log(this.iframe)

      let requestFullScreen = this.iframe.requestFullScreen || this.iframe.mozRequestFullScreen || this.iframe.webkitRequestFullScreen;
      if (requestFullScreen) {
        requestFullScreen.bind(this.iframe)();
      }
    },
    methodOnPlayerReady(e: any) {
      console.log(e)
      console.log('player ready')
      console.log('youtube id', this.youtubeId)

      if( this.player ) {
        this.duration = this.player.getDuration()
        this.currentTime = this.player.getCurrentTime()

        try {
            // Disable captions completelly
            this.player.unloadModule("captions");
            this.player.unloadModule("cc");
        } catch (exception) { 

          console.log('unload module', exception)
        }
      }

      if( this.theInterval ) {
        clearInterval(this.theInterval)
      }
      
      // this.theInterval = setInterval(this.playInterval, 1000);
    },
    methodOnPlayerStateChange(e: any) {
      console.log(e)
      console.log('state changed')

      if( this.player ) {
        const playerState = this.player.getPlayerState()
        console.log('from player change', playerState)

        if( playerState == 3 ) {
          this.showWatermark = true
        }

        if( playerState == 2 ) {
          this.showWatermark = true
        }
      }
    },
    detectClick(e: any): void {
      const playerContainer = document.querySelector('#player-container')

      const rect = playerContainer?.getBoundingClientRect()

      if( rect ) {
        const diff = e.clientX - rect.x
        const width = playerContainer?.clientWidth

        if( width && width > 0 ) {
          const selectedPercentage = diff / width * 100
          const playAt = Math.floor((selectedPercentage / 100) * this.duration)
          this.player.seekTo(playAt)
          this.currentTime = playAt
        }
      }
    },
    playPause(): void {
      
      const playerState = this.player.getPlayerState()
      console.log(playerState)
      if( playerState == 1 ) {
          this.player.pauseVideo()
          this.isPlayed = false
          this.showWatermark = true
      }

      if( playerState == 2 ) {
          this.player.playVideo()
          this.isPlayed = true
          this.showWatermark = false
      }

      if( playerState == 5 ) {
        this.player.playVideo()
        this.isPlayed = true
        this.showWatermark = false

        if( this.theInterval ) {
          clearInterval(this.theInterval)
        }

        this.theInterval = setInterval(this.playInterval, 1000);
      }
    },
    async withYoutube(youtubeId: string) {

        this.duration = 0
        this.currentTime = 0

        if( this.theInterval ) {
          clearInterval(this.theInterval)
        }

        this.processEnded = false
        const ytExist = document.querySelector('#youtube-iframe')
        if( !ytExist ) {
          console.log('YT not exist')
          var firstScriptTag = document.getElementsByTagName('script')[0];
          var tag = document.createElement('script');
          tag.id = 'youtube-iframe';
          tag.src = "https://www.youtube.com/iframe_api";
          firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);
        }

        await new Promise(r => setTimeout(r, 1000));
        this.playYoutube(youtubeId)
    },
    async playYoutube(youtubeId: string ) {
      if( this.player ) {
        this.player.destroy()
      }
      console.log('play the youtube', youtubeId)
      this.player = await new YT.Player('player', {
            videoId: youtubeId,
            playerVars: {
                'playsinline': 1,
                'enablejsapi': 1,
                'rel': 0,
                'autoplay': 0,
                'modestbranding': 1,
                'showinfo': 0,
                'controls': 0,
                'cc_load_policy': 3,
                'iv_load_policy': 3,

            },
            events: {
                'onReady': this.onPlayerReady,
                'onStateChange': this.onPlayerStateChange,
            },
        });
    },
    getPublic() {
      console.log('public')
    },
    sideScroll(
      el: string,
      direction: string,
      speed: number,
      distance: number,
      step: number
    ) {
      var scrollAmount = 0 as number
      const element: any = this.$refs[el]
      // console.log(element)
      var slideTimer = setInterval(function() {
        if (direction == 'left') {
          element.scrollLeft -= step
        } else {
          element.scrollLeft += step
        }
        scrollAmount += step
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer)
        }
      }, speed)
      // console.log('width',element.scrollWidth)
      // console.log('left',element.scrollLeft)
    },
    isLinkYoutube(data: string | undefined): boolean {

      if( data != undefined && data.includes('youtube.com') ) {
        return true
      }

      return false
    },
    getYoutubeId(url: string) {
      const z = url.match(/embed\/(.*?)\?/)
      if( z && z.length == 2 ) {
        return z[1]
      }
      return ''
    },
    initYtScript() {
      
      // const width = playerContainer.offsetWidth
      // const height = (width / 1.5)

      // progressBarContainer.setAttribute('style','width: ' + (width - playButtonContainer.offsetWidth - settingContainer.offsetWidth - 20) + 'px')

      // const yt = new YT
      
    },
    showConfirmWebinarQuiz() {
      this.showModalConfirmWebinarQuiz = true
      this.modalQuiz.title = 'Selesaikan ' + this.webinarQuizData?.name
      this.modalQuiz.textOne =
        'Apakah kamu yakin ingin menyelesaikan ' +
        this.webinarQuizData?.name +
        ' ini?'
    },
    showConfirmPrePostTest() {
      this.showModalConfirmPrePostTest = true
      this.modalQuiz.title =
        'Selesaikan ' + (this.testType ? 'Post-Test' : 'Pre-Test')
      this.modalQuiz.textOne =
        'Apakah kamu yakin ingin menyelesaikan ' +
        (this.testType ? 'post-Test' : 'pre-Test') +
        ' ini?'
    },
    showConfirmQuiz() {
      this.showModalConfirmQuiz = true
      this.modalQuiz.title = 'Selesaikan Kuis'
      this.modalQuiz.textOne = 'Apakah kamu yakin ingin menyelesaikan kuis ini?'
    },
    hideConfirmQuiz() {
      if (!this.isLoadingSubmitQuiz) {
        this.showModalConfirmQuiz = false
        this.showModalConfirmPrePostTest = false
        this.showModalConfirmWebinarQuiz = false
      }
    },
    nextAfterChoose(length: number) {
      if (length != this.questionIndex + 1) {
        this.questionIndex = this.questionIndex + 1
        const el: any = this.$refs['q' + this.questionIndex]
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
          })
        }
      }
    },
    countMaterialDone(materi: any) {
      var done = 0
      materi.forEach((el: any) => {
        if (el.data.is_done) {
          done++
        }
      })
      return done
    },
    prevQuestion() {
      this.questionIndex = this.questionIndex - 1
      const el: any = this.$refs['q' + this.questionIndex]
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
    nextQuestion() {
      this.questionIndex = this.questionIndex + 1
      const el: any = this.$refs['q' + this.questionIndex]
      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
        })
      }
    },
    changeQuestion(number: number) {
      this.questionIndex = number
    },
    async checkQuiz() {
      await this.getOneQuiz(this.selectedMaterialItem.data.id)
      this.showModalStartQuiz = false
      this.displayResult = false
      this.showQuiz = true
    },
    openModalQuiz(data: any) {
      this.showModalStartQuiz = true
      this.modalQuiz.title = 'Mulai Kuis'
      this.modalQuiz.textOne =
        'Apakah kamu yakin ingin memulai ' + data.name + ' sekarang?'
      this.modalQuiz.textTwo = 'Nilai kelulusan:  ' + data.min_score
    },
    closeModalStartQuiz() {
      this.showModalStartQuiz = false
    },
    async getOneQuiz(id: number) {
      this.payloadQuiz.item_ids = []
      this.payloadQuiz.answers = []
      this.questionIndex = 0
      if (this.quizData?.id != id) {
        const { success, data } = await QuizModel.list(id)
        if (success) {
          this.questionIndex = 0
          this.quizData = data
        }
      }
      for (let i = 0; i < this.quizData?.items?.length; i++) {
        this.payloadQuiz.item_ids.push(this.quizData.items[i].id)
        this.payloadQuiz.answers.push(null)
      }
    },

    async submitPrePostTest() {
      const isNull = this.payloadQuiz.answers.some(function(el: any) {
        return el == null
      })
      let type = this.testType ? 'Post-Test' : 'Pre-Test'
      if (!isNull) {
        this.isLoadingSubmitQuiz = true
        try {
          let payload = {}
          if (this.testType) {
            payload = {
              answers: this.payloadQuiz.answers,
              item_ids: this.payloadQuiz.item_ids,
              post_test_id: this.prePostTestData.id,
            }
          } else {
            payload = {
              answers: this.payloadQuiz.answers,
              item_ids: this.payloadQuiz.item_ids,
              pre_test_id: this.prePostTestData.id,
            }
          }
          const { success, data } = await ClassroomModel.submitPrePostTest(
            this.classroomCode,
            this.testType,
            payload
          )
          this.isLoadingSubmitQuiz = false
          this.hideConfirmQuiz()
          if (this.testType == 0) {
            this.checkIsExistPostTest()
          }
          if (success) {
            if (this.testType == 1) {
              this.result.quizName = type
              this.result.score = data.score
              this.result.countTrue = Math.round(
                (this.prePostTestData.items?.length * data.score) / 100
              )
              this.result.countFalse =
                this.prePostTestData.items?.length -
                Math.round(
                  (this.prePostTestData.items?.length * data.score) / 100
                )
              this.result.minScore = this.postTestClass.min_score
              this.displayResultPostTest = true
              this.postTestClass.score = data.score
              if (data.score >= this.postTestClass?.min_score) {
                this.postTestClass.is_done = true
                this.isDonePostTest = true
                this.isAttemptPostTest = true
              } else {
                this.isDonePostTest = false
                this.isAttemptPostTest = true  
              }
            } else {
              this.result.score = data.score
              this.result.countTrue = Math.round(
                (this.prePostTestData.items?.length * data.score) / 100
              )
              this.result.countFalse =
                this.prePostTestData.items?.length -
                Math.round(
                  (this.prePostTestData.items?.length * data.score) / 100
                )
              this.displayResultPreTest = true
            }
          } else {
            let text = ''
            let notAnswered = [] as any
            this.payloadQuiz.answers.forEach((el: any, i: number) => {
              if (el == null) {
                notAnswered.push(i + 1)
              }
            })
            text = [
              notAnswered.slice(0, -1).join(', '),
              notAnswered.slice(-1)[0],
            ].join(notAnswered?.length < 2 ? '' : ' dan ')
            this.modalQuiz.imageUrl =
              '/assets/images/modal/illustration-test-not-completed.svg'
            this.modalQuiz.title = 'Maaf, ' + type + ' kamu belum selesai'
            this.modalQuiz.textOne = 'Harap selesaikan soal nomor ' + text
            this.showModalNotDoneQuiz = true
          }
        } catch (err) {
          this.isLoadingSubmitQuiz = false
          const error: any = err
          this.showAlert = true
          this.modalAlert.status = 'error'
          this.modalAlert.title = 'Gagal'
          this.modalAlert.description = error.response.data.errors[0].message
        }
      } else {
        this.hideConfirmQuiz()
        let text = ''
        let notAnswered = [] as any
        this.payloadQuiz.answers.forEach((el: any, i: number) => {
          if (el == null) {
            notAnswered.push(i + 1)
          }
        })
        text = [
          notAnswered.slice(0, -1).join(', '),
          notAnswered.slice(-1)[0],
        ].join(notAnswered?.length < 2 ? '' : ' dan ')
        this.modalQuiz.imageUrl =
          '/assets/images/modal/illustration-test-not-completed.svg'
        this.modalQuiz.title = 'Maaf, ' + type + ' kamu belum selesai'
        this.modalQuiz.textOne = 'Harap selesaikan soal nomor ' + text
        this.showModalNotDoneQuiz = true
      }
    },
    async submitAnswer() {
      const isNull = this.payloadQuiz.answers.some(function(el: any) {
        return el == null
      })
      if (!isNull) {
        this.isLoadingSubmitQuiz = true
        try {
          const { success, data } = await QuizModel.add(
            this.quizData.id,
            this.payloadQuiz
          )
          this.isLoadingSubmitQuiz = false
          this.hideConfirmQuiz()
          if (success) {
            this.result.quizName = this.quizData?.name
            this.result.countTrue = data.correct
            this.result.countFalse = data.wrong
            this.result.score = data.score
            this.result.minScore =
              this.quizData.min_score > 0
                ? this.quizData.min_score
                : this.quizData.is_exam
                ? 60
                : 80
            this.displayResult = true
            if (this.result.score >= this.result.minScore) {
              this.materials.forEach((el: any) => {
                if (el.material?.length > 0) {
                  el.material.forEach((e: any) => {
                    if (e.type == 'quiz' && e.data.id == this.quizData.id) {
                      e.data.is_done = true
                    }
                  })
                }
              })
              this.checkClassroomProgress()
            }
          } else {
            let text = ''
            let notAnswered = [] as any
            this.payloadQuiz.answers.forEach((el: any, i: number) => {
              if (el == null) {
                notAnswered.push(i + 1)
              }
            })
            text = [
              notAnswered.slice(0, -1).join(', '),
              notAnswered.slice(-1)[0],
            ].join(notAnswered.length < 2 ? '' : ' dan ')
            this.modalQuiz.imageUrl =
              '/assets/images/modal/illustration-test-not-completed.svg'
            this.modalQuiz.title = 'Maaf, kuis kamu belum selesai'
            this.modalQuiz.textOne = 'Harap selesaikan soal nomor ' + text
            this.showModalNotDoneQuiz = true
          }
        } catch (err) {
          this.isLoadingSubmitQuiz = false
          const error: any = err
          this.showAlert = true
          this.modalAlert.status = 'error'
          this.modalAlert.title = 'Gagal'
          this.modalAlert.description = error.response.data.errors[0].message
        }
      } else {
        this.hideConfirmQuiz()
        let text = ''
        let notAnswered = [] as any
        this.payloadQuiz.answers.forEach((el: any, i: number) => {
          if (el == null) {
            notAnswered.push(i + 1)
          }
        })
        text = [
          notAnswered.slice(0, -1).join(', '),
          notAnswered.slice(-1)[0],
        ].join(notAnswered.length < 2 ? '' : ' dan ')
        this.modalQuiz.imageUrl =
          '/assets/images/modal/illustration-test-not-completed.svg'
        this.modalQuiz.title = 'Maaf, kuis kamu belum selesai'
        this.modalQuiz.textOne = 'Harap selesaikan soal nomor ' + text
        this.showModalNotDoneQuiz = true
      }
    },
    async submitWebinarQuiz() {
      const isNull = this.payloadWebinarQuiz.answers.some(function(el: any) {
        return el == null
      })
      if (!isNull) {
        this.isLoadingSubmitQuiz = true
        try {
          const { success, data } = await WebinarQuizModel.submitV2(
            this.webinarQuizData.id,
            this.payloadWebinarQuiz
          )
          this.isLoadingSubmitQuiz = false
          this.hideConfirmQuiz()
          if (success) {
            this.isDoneWebinarQuiz = true
            this.webinarQuizData.items.map((e: any) => {
              const findResult = data.overview.find(
                (el: any) => el.item_id == e.id
              )
              e.result = findResult.result
            })
            this.showAlert = true
            this.modalAlert.status = 'success'
            this.modalAlert.title = 'Selamat'
            this.modalAlert.description = 'Kamu mendapatkan nilai ' + data.score
          }
          await this.getWebinarQuiz()
          this.getListWebinar()
        } catch (err) {
          this.isLoadingSubmitQuiz = false
          const error: any = err
          this.showAlert = true
          this.modalAlert.status = 'error'
          this.modalAlert.title = 'Gagal'
          this.modalAlert.description = error.response.data.errors[0].message
        }
      } else {
        this.hideConfirmQuiz()
        let text = ''
        let notAnswered = [] as any
        this.payloadWebinarQuiz.answers.forEach((el: any, i: number) => {
          if (el == null) {
            notAnswered.push(i + 1)
          }
        })
        text = [
          notAnswered.slice(0, -1).join(', '),
          notAnswered.slice(-1)[0],
        ].join(notAnswered.length < 2 ? '' : ' dan ')
        this.modalQuiz.imageUrl =
          '/assets/images/modal/illustration-test-not-completed.svg'
        this.modalQuiz.title =
          'Maaf, ' + this.webinarQuizData.name + ' kamu belum selesai'
        this.modalQuiz.textOne = 'Harap selesaikan soal nomor ' + text
        this.showModalNotDoneQuiz = true
      }
    },
    closeModalNotDoneQuiz() {
      this.showModalNotDoneQuiz = false
    },
    onQuestionFile(event: any) {
      this.formQuestion.file = event.target.files[0]
    },
    removeQuestionFile() {
      this.formQuestion.file = ''
    },
    dragoverQuestion(event: any) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-[#E8EDFF]')) {
        event.currentTarget.classList.remove('bg-[#FFFFFF]')
        event.currentTarget.classList.add('bg-[#E8EDFF]')
      }
    },
    dragleaveQuestion(event: any) {
      // Clean up
      event.currentTarget.classList.add('bg-[#FFFFFF]')
      event.currentTarget.classList.remove('bg-[#E8EDFF]')
    },
    dropQuestion(event: any) {
      event.preventDefault()
      ;(this.$refs.questionFile as any).files = event.dataTransfer.files
      this.formQuestion.file = event.dataTransfer.files[0]
      // Clean up
      event.currentTarget.classList.add('bg-[#FFFFFF]')
      event.currentTarget.classList.remove('bg-[#E8EDFF]')
    },

    async nextMaterialAfterPreTest() {
      this.isDonePreTest = true
      await this.getMaterialsList()
      this.showPrePost = false
      this.showQuiz = false
      this.displayResultPreTest = false
    },
    refreshPostTest() {
      // await this.checkIsExistPostTest()
      this.displayResultPostTest = false
      this.showPrePost = false
    },
    async redirectClassroom() {
      await this.getMaterialsList()
      this.showAlertQuiz = false
      this.displayResult = false
      this.showQuiz = false
    },
    isActiveMateri(item: any) {
      return this.toggledMaterial.indexOf(item) >= 0
    },
    toggleMateri(item: any) {
      const index = this.toggledMaterial.indexOf(item)
      if (index >= 0) {
        this.toggledMaterial.splice(index, 1)
        return
      }
      this.toggledMaterial.push(item)
    },
    async getOneVideo(id: number) {
      this.assets = []
      this.resolutionVideos = []
      await ClassroomModel.detailSubject(id)
        .then(async res => {
          if (res.success) {
            
            if( this.theInterval ) {
              clearInterval(this.theInterval)
            }

            this.assets = res.data?.assets
            
            if( this.isLinkYoutube(res.data?.video) ) {
              this.isYoutube = true
              // this.youtubeId = this.getYoutubeId(this.selectedMaterialItem.data.link)
              if( this.player ) {
                console.log('destroy old player first')
                this.player.destroy()
                this.isPlayed = false
              }
              this.withYoutube(this.getYoutubeId(this.selectedMaterialItem.data.link))
            }

            if (res.data?.resolutions?.length > 0) {
              this.isExistResolution = true
              this.resolutionVideos = res.data
              
            } else {
              this.isExistResolution = false
            }
            this.settingQuality(this.resolutionVideos)
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    changePage(to: string) {
      if (this.showQuiz || this.showPrePost || this.showWebinarQuiz) {
        this.redirect = to
        this.modalQuiz.imageUrl =
          '/assets/images/modal/illustration-test-not-completed.svg'
        if (this.showQuiz) {
          this.modalQuiz.title = 'Maaf, kuis kamu belum selesai'
          this.modalQuiz.textOne =
            'Apakah kamu yakin ingin meninggalkan kuis ini?'
        } else if (this.showPrePost) {
          if (this.testType) {
            this.modalQuiz.title = 'Maaf, post-test kamu belum selesai'
            this.modalQuiz.textOne =
              'Apakah kamu yakin ingin meninggalkan post-test ini?'
          } else {
            this.modalQuiz.title = 'Maaf, pre-test kamu belum selesai'
            this.modalQuiz.textOne =
              'Apakah kamu yakin ingin meninggalkan pre-test ini?'
          }
        } else if (this.showWebinarQuiz) {
          if (this.isDoneWebinarQuiz) {
            this.modalQuiz.imageUrl =
              '/assets/images/modal/illustration-attention.svg'
            this.modalQuiz.title = 'Apakah kamu yakin ingin keluar?'
            this.modalQuiz.textOne = ''
          } else {
            if (this.webinarQuizData?.quiz_type) {
              this.modalQuiz.title =
                'Maaf, post-test webinar kamu belum selesai'
              this.modalQuiz.textOne =
                'Apakah kamu yakin ingin meninggalkan post-test ini?'
            } else {
              this.modalQuiz.title = 'Maaf, pre-test webinar kamu belum selesai'
              this.modalQuiz.textOne =
                'Apakah kamu yakin ingin meninggalkan pre-test ini?'
            }
          }
        }
        this.showAlertQuiz = true
      } else {
        if (to == '-1') {
          this.$router.go(-1)
        } else {
          this.$router.push(to)
        }
      }
    },
    confirmAlertQuiz() {
      this.showQuiz = false
      this.showPrePost = false
      this.showAlertQuiz = false
      this.showPostTest = false
      if (this.redirect != '') {
        if (this.redirect == 'quiz') {
          this.redirectClassroom()
        } else if (this.redirect == 'pre-test') {
          this.showPrePost = false
          this.showAlertQuiz = false
        } else if (this.redirect == 'webinar-quiz') {
          this.showWebinarQuiz = false
          this.showAlertQuiz = false
        } else {
          if (this.redirect == '-1') {
            this.$router.go(-1)
          } else {
            this.$router.push(this.redirect)
          }
        }
      } else {
        this.openMaterial(this.tempMaterial, this.tempMaterialItem)
      }
    },
    closeAlertQuiz() {
      this.showAlertQuiz = false
    },
    async openMaterial(materials: any, item: any) {
      this.isYoutube = false
      this.youtubeId = ''
      this.showPostTest = false
      this.showWatermark = true
      this.minuteText = '00';
      this.secondText = '00';
      this.percentage = 0
      this.player = null
      if (this.isDonePreTest) {
        this.redirect = ''
        if (this.showQuiz || this.showPrePost) {
          if (this.showQuiz) {
            this.modalQuiz.title = 'Maaf, kuis kamu belum selesai'
            this.modalQuiz.textOne =
              'Apakah kamu yakin ingin meninggalkan kuis ini?'
          } else if (this.testType) {
            this.modalQuiz.title = 'Maaf, post-test kamu belum selesai'
            this.modalQuiz.textOne =
              'Apakah kamu yakin ingin meninggalkan post-test ini?'
          } else {
            this.modalQuiz.title = 'Maaf, pre-test kamu belum selesai'
            this.modalQuiz.textOne =
              'Apakah kamu yakin ingin meninggalkan pre-test ini?'
          }
          this.showAlertQuiz = true
          this.tempMaterial = materials
          this.tempMaterialItem = item
        } else {
          if (item.data.is_done || !this.isPrakerja) {
            this.selectedMaterial = materials
            this.selectedMaterialItem = item
            if (this.selectedMaterialItem) {
              if (item.type == 'video') {
                await this.getOneVideo(this.selectedMaterialItem.data.id)
                this.openVideoControl()
                const file = document.querySelector(
                  '#vplyr'
                ) as HTMLVideoElement
                if (file != null) {
                  file.load()
                }
              } else if (item.type == 'module') {
                if (this.selectedMaterialItem.data.is_done == false) {
                  await ClassroomModel.ViewModul(
                    this.selectedMaterialItem.data.id
                  )
                  await this.refreshMaterials()
                }
              } else if (item.type == 'quiz') {
                await this.getOneQuiz(item.data.id)
              }
            }
          } else {
            const filteredMaterial = this.materials.find((e: any) => !e.is_done)
            this.selectedMaterial = filteredMaterial
            const filteredMaterialItem = filteredMaterial.material.find(
              (e: any) => !e.data.is_done
            )
            if (filteredMaterialItem) {
              if (Object.keys(this.selectedMaterialItem)?.length == 0) {
                this.selectedMaterialItem = filteredMaterialItem
              }
              if (
                this.selectedMaterialItem?.data?.material_ordering + 1 !=
                  filteredMaterialItem.data.material_ordering &&
                this.selectedMaterialItem.data.material_ordering !=
                  item.data.material_ordering
              ) {
                this.modalPackStatusError = true
                if (this.selectedMaterialItem?.type == 'video') {
                  this.imageUrlModal =
                    '/assets/images/modal/must-finished-video-before.svg'
                } else if (this.selectedMaterialItem?.type == 'module') {
                  this.imageUrlModal =
                    '/assets/images/modal/must-finished-module-before.png'
                } else if (this.selectedMaterialItem?.type == 'quiz') {
                  this.imageUrlModal =
                    '/assets/images/modal/must-finished-task-before.svg'
                } else {
                  this.imageUrlModal =
                    '/assets/images/modal/must-finished-video-before.svg'
                }
                this.firstTextModal =
                  'Kamu harus menyelesaikan materi ini dahulu '
                this.secondTextModal = ''
                this.firstButtonModal = 'OK'
              }
              if (
                this.selectedMaterialItem?.data?.material_ordering ==
                filteredMaterialItem.data.material_ordering
              ) {
                const el: any = this.$refs['materi']
                if (el) {
                  el.scrollIntoView({
                    behavior: 'smooth',
                  })
                }
                return
              }
              this.selectedMaterialItem = filteredMaterialItem
              if (this.selectedMaterialItem.type == 'video') {
                await this.getOneVideo(this.selectedMaterialItem.data.id)
                if (this.isPrakerja) {
                  this.closeVideoControl()
                } else {
                  this.openVideoControl()
                }
                const file = document.querySelector(
                  '#vplyr'
                ) as HTMLVideoElement
                if (file != null) {
                  file.load()
                }
              } else if (this.selectedMaterialItem.type == 'module') {
                if (this.selectedMaterialItem.data.is_done == false) {
                  await ClassroomModel.ViewModul(
                    this.selectedMaterialItem.data.id
                  )
                  await this.refreshMaterials()
                }
              } else if (this.selectedMaterialItem.type == 'quiz') {
                await this.getOneQuiz(this.selectedMaterialItem.data.id)
              }
            } else {
              this.selectedMaterialItem = item
              if (this.selectedMaterialItem.type == 'video') {
                await this.getOneVideo(this.selectedMaterialItem.data.id)
                this.openVideoControl()
                const file = document.querySelector(
                  '#vplyr'
                ) as HTMLVideoElement
                if (file != null) {
                  file.load()
                }
              } else if (this.selectedMaterialItem.type == 'quiz') {
                await this.getOneQuiz(this.selectedMaterialItem.data.id)
              }
            }
          }
          const el: any = this.$refs['materi']
          if (el) {
            el.scrollIntoView({
              behavior: 'smooth',
            })
          }
        }
      } else {
        this.modalQuiz.imageUrl =
          '/assets/images/modal/illustration-test-not-completed.svg'
        this.modalQuiz.title = 'Materi belum bisa dilihat'
        this.modalQuiz.textOne =
          'Kamu harus mengerjakan pre-test terlebih dahulu'
        this.showModalNotDoneQuiz = true
      }
    },
    async onEndVideo() {
      const { success } = await ClassroomModel.ViewSubject(
        this.selectedMaterialItem.data.id
      )
      await this.refreshMaterials()
      if (success) {
        this.modalPackStatusMateri = true
        this.imageUrlModal = '/assets/images/modal/success-watch-video.svg'
        this.firstTextModal = 'Video Berhasil ditonton. '
        this.secondTextModal = 'Tonton materi selanjutnya'
        this.firstButtonModal = 'Lanjut'
      }
    },
    nextMateri() {
      if (
        this.selectedMaterialItem?.data?.material_ordering == this.countMateri
      ) {
        this.modalPackStatusError = true
        this.imageUrlModal = '/assets/images/modal/all-video-watched.svg'
        this.firstTextModal = 'Selamat!'
        this.secondTextModal =
          'Anda telah menyelesaikan semua materi pada kelas ini'
        this.firstButtonModal = 'OK'
        this.modalPackStatusMateri = false
      } else {
        this.modalPackStatusMateri = false
        this.changeMateri(
          this.selectedMaterialItem?.data?.material_ordering,
          'next'
        )
      }
    },

    changeMateri(ordering: number, type: string) {
      let material = null
      let materialItem = null
      if (type == 'next') {
        this.materials.forEach((el: any) => {
          if (el.material.length > 0) {
            el.material.forEach((e: any) => {
              if (e.data.material_ordering == ordering + 1) {
                material = el
                materialItem = e
              }
            })
          }
        })
      } else {
        this.materials.forEach((el: any) => {
          if (el.material.length > 0) {
            el.material.forEach((e: any) => {
              if (e.data.material_ordering == ordering - 1) {
                material = el
                materialItem = e
              }
            })
          }
        })
      }
      this.openMaterial(material, materialItem)
    },

    async refreshMaterials() {
      await ClassroomModel.getMaterials(this.classroomCode)
        .then(res => {
          const orderingMaterials = (res.data as any[]).sort(
            (a, b) => a.ordering - b.ordering
          )
          const find = orderingMaterials.filter(
            (el: any) => el.ordering == this.selectedMaterial.ordering
          )
          if (find) {
            this.selectedMaterial = find[0]
          }

          const tempMaterial = [] as any[]
          orderingMaterials.forEach((el: any) => {
            const findNotDone = el.material.find((e: any) => !e.data?.is_done)
            if (findNotDone) {
              tempMaterial.push({
                ...el,
                is_done: false,
              })
            } else {
              tempMaterial.push({
                ...el,
                is_done: true,
              })
            }
          })

          if (tempMaterial.length) {
            this.materials = tempMaterial
          }
          const isNotDone = this.materials.find((e: any) => !e.is_done)
          if (isNotDone) {
            this.isDoneMateri = false
          } else {
            this.isDoneMateri = true
          }
          this.checkClassroomProgress()
        })
        .catch(err => {
          console.log(err)
        })
    },
    async getMaterialsList() {
      await ClassroomModel.getMaterials(this.classroomCode)
        .then(async res => {
          if (res.data.length > 0) {
            this.isExistMaterial = true
            const el = document.getElementById('play')
            this.isNoAccomplishement = false
            el?.children[0].children[0].children[1].setAttribute(
              'style',
              'pointer-events:none;'
            )
            const orderingMaterials = (res?.data as any[]).sort(
              (a, b) => a.ordering - b.ordering
            )
            let tempCountMateri = 0
            const tempMaterial = [] as any[]
            orderingMaterials.forEach((el: any) => {
              tempCountMateri += el.material.length
              const findNotDone = el.material.find((e: any) => !e.data?.is_done)
              if (findNotDone) {
                tempMaterial.push({
                  ...el,
                  is_done: false,
                })
              } else {
                tempMaterial.push({
                  ...el,
                  is_done: true,
                })
              }
            })
            this.countMateri = tempCountMateri

            if (tempMaterial.length) {
              this.materials = tempMaterial
              if (this.isDonePreTest) {
                if (this.isVideo || this.isModule) {
                  let material = null
                  let materialItem = null
                  this.materials.forEach((el: any) => {
                    if (el.material.length > 0) {
                      el.material.forEach((e: any) => {
                        if (
                          this.isVideo &&
                          e.type == 'video' &&
                          e.data.id == this.isVideo
                        ) {
                          material = el
                          materialItem = e
                        } else if (
                          this.isModule &&
                          e.type == 'module' &&
                          e.data.id == this.isModule
                        ) {
                          material = el
                          materialItem = e
                        }
                      })
                    }
                  })
                  if (material != null && materialItem != null) {
                    this.openMaterial(material, materialItem)
                    return
                  }
                }
                const find = tempMaterial.find(e => !e.is_done)
                if (find) {
                  this.isDoneMateri = false
                  const findItem = find.material.find(
                    (e: any) => !e.data?.is_done
                  )
                  if (findItem) {
                    this.selectedMaterial = find
                    this.selectedMaterialItem = findItem
                  } else {
                    this.selectedMaterial = find
                    this.selectedMaterialItem = find.material[0]
                  }
                } else {
                  if (this.isExistPostTest) {
                    this.openPostTestPage()
                  } else {
                    this.isDoneMateri = true
                    this.selectedMaterial = tempMaterial[0]
                    if (tempMaterial[0]?.material.length) {
                      this.selectedMaterialItem = tempMaterial[0]?.material[0]
                    } else {
                      this.selectedMaterialItem = {}
                    }
                  }
                }

                if (this.selectedMaterialItem?.type == 'video') {
                  await this.getOneVideo(this.selectedMaterialItem.data.id)
                  if (
                    !this.isPrakerja ||
                    this.selectedMaterialItem.data.is_done
                  ) {
                    this.openVideoControl()
                  } else {
                    this.closeVideoControl()
                  }
                  const file = document.querySelector(
                    '#vplyr'
                  ) as HTMLVideoElement
                  if (file != null) {
                    file.load()
                  }
                } else if (this.selectedMaterialItem?.type == 'module') {
                  if (this.selectedMaterialItem.data?.is_done == false) {
                    await ClassroomModel.ViewModul(
                      this.selectedMaterialItem.data.id
                    )
                    this.refreshMaterials()
                  }
                } else if (this.selectedMaterialItem?.type == 'quiz') {
                  await this.getOneQuiz(this.selectedMaterialItem.data.id)
                }
              } else {
                return
              }
            }
          } else {
            this.isExistMaterial = false
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    async init() {
      await this.checkUserPrakerja()
      await this.checkIsExistPreTest()
      await this.getMaterialsList()
      this.checkIsExistPostTest()
      if (this.classroomLevel.classroom?.is_must_webinar) {
        await this.getListWebinar()
        await this.getWebinarRecord()
      }
      if (this.classroomLevel.classroom?.is_must_submission) {
        await this.getTaskList()
      }
      this.checkClassroomProgress()
      this.openTab(this.tab)
    },
    checkClassroomProgress() {
      this.countProgress = 0
      this.countDoneProgress = 0
      this.isVideoDone = true
      this.isModuleDone = true
      this.isQuizDone = true
      this.isWebinarDone = true
      this.isSubmissionDone = true
      this.isExamDone = true
      if (
        this.classroomLevel.certificate != '' ||
        this.classroomLevel.is_level_done
      ) {
        this.countProgress = 1
        this.countDoneProgress = 1
      } else {
        this.materials.forEach((el: any) => {
          if (el.material.length) {
            el.material.forEach((e: any) => {
              if (e.type == 'video') {
                if (this.classroomLevel.classroom.is_must_subject) {
                  if (e.data.is_done) {
                    this.countDoneProgress++
                  } else {
                    this.isVideoDone = false
                  }
                  this.countProgress++
                } else {
                  this.isVideoDone = true
                }
              } else if (e.type == 'module') {
                if (this.classroomLevel.classroom.is_must_module) {
                  if (e.data.is_done) {
                    this.countDoneProgress++
                  } else {
                    this.isModuleDone = false
                  }
                  this.countProgress++
                } else {
                  this.isModuleDone = true
                }
              } else if (e.type == 'quiz') {
                if (e.data.is_exam) {
                  if (this.classroomLevel.classroom.is_must_exam) {
                    if (e.data.is_done) {
                      this.countDoneProgress++
                    } else {
                      this.isExamDone = false
                    }
                    this.countProgress++
                  } else {
                    this.isExamDone = true
                  }
                } else {
                  if (this.classroomLevel.classroom.is_must_quiz) {
                    if (e.data.is_done) {
                      this.countDoneProgress++
                    } else {
                      this.isQuizDone = false
                    }
                    this.countProgress++
                  } else {
                    this.isQuizDone = true
                  }
                }
              }
            })
          }
        })
        if (this.classroomLevel.classroom.is_must_webinar) {
          this.countProgress++
          if (this.classroomLevel.is_webinar_done) {
            this.isWebinarDone = true
            this.countDoneProgress++
          } else {
            if (this.webinarRecord?.length) {
              const viewedWebinarRecord = this.webinarRecord.filter(
                (el: any) => {
                  if (el.is_viewed) {
                    return el
                  }
                }
              ).length
              if (this.webinarRecord?.length == viewedWebinarRecord) {
                this.countDoneProgress++
                this.isWebinarDone = true
              } else {
                this.isWebinarDone = false
              }
              // this.countProgress = this.countProgress + this.webinarRecord.length
              // this.webinarRecord.forEach((el: any) => {
              //   if (el.is_viewed) {
              //     this.countDoneProgress++
              //   }
              // })
            }
          }
        } else {
          this.isWebinarDone = true
        }
        // console.log(this.countProgress)
        if (this.classroomLevel.classroom.is_must_submission) {
          this.countProgress = this.countProgress + this.tasks?.length
          if (this.tasks?.length > 0) {
            this.tasks.forEach((el: any) => {
              if (el.score > 0) {
                this.countDoneProgress++
              } else {
                this.isSubmissionDone = false
              }
            })
          } else {
            this.isSubmissionDone = true
          }
        } else {
          this.isSubmissionDone = true
        }
      }
      // console.log('count progress', this.countProgress)
      // console.log('count progress done', this.countDoneProgress)
    },
    dateFormat(date: string) {
      return moment(date)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
    },
    countWatchedVideo() {
      const watchedVideo = this.subjects?.filter(e => e.is_viewed === true)
      return watchedVideo?.length
    },
    countWatchedModule() {
      const watchedModule = this.modul?.filter(e => e.is_read === true)
      return watchedModule?.length
    },
    countProgressMateri() {
      let isDoneMateri = 0
      this.materials.forEach((el: any) => {
        if (el.material?.length) {
          el.material.forEach((e: any) => {
            if (e.data.is_done) {
              isDoneMateri++
            }
          })
        }
      })
      return isDoneMateri
    },

    onTaskFile(event: any) {
      if (event.target.files[0]) {
        this.formTask.file = event.target.files[0]
      }
    },

    onChangeSelectTaskType() {
      this.formTask.file = null
      this.errorTaskLinkName = false
    },

    checkFormTaskFileLink() {
      if (this.formTask.file.includes('https://')) {
        this.errorTaskLinkName = false
      } else {
        this.errorTaskLinkName = true
      }
    },

    submitUploadTask() {
      this.showUploadProgress = true

      const formData = new FormData()
      formData.append('file', this.formTask.file)
      if (this.formTask.note) {
        formData.append('note', this.formTask.note)
      }

      const token = Cookies.getJSON('auth')

      axios
        .post(
          'https://api.sekolahdesain.id/classroom/private/api/v1/classroom/task/' +
            this.selectedTask.id +
            '/submit',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token.token}`,
            },
            onUploadProgress: (progressEvent: any) => {
              this.uploadPercentage = Number(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              )
            },
          }
        )
        .then(async () => {
          this.showUploadProgress = false
          this.formTask.note = ''
          this.formTask.file = null
          this.typeFile = 'berkas'
          this.toast.success('Berhasil upload tugas')
          await this.getTaskList()
          const findTask = this.tasks.find(
            (el: any) => el.id == this.selectedTask.id
          )
          this.selectedTask = findTask
        })
        .catch(() => {
          this.toast.error('Gagal upload tugas')
        })
    },

    async getClassRoomLevelDetail(classroomLevelCode: string) {
      try {
        await this.$store
          .dispatch('classroomStore/fetchDetailClassroom', classroomLevelCode)
          .then(() => {
            const {
              'classroomStore/getLoadingDetailClassroom': getLoadingDetailClassroom,
              'classroomStore/getDetailClassroom': getDetailClassroom,
            } = this.$store.getters
            this.classroomLevel = getDetailClassroom
            if (this.classroomLevel?.forum.length < 6 && this.classroomLevel?.whatsapp_link.length < 6) {
              this.isExistForum = false
            }
            if (this.classroomLevel.is_locked) {
              this.isAutoPlay = false
            }
            // this.isLoading = getLoadingDetailClassroom
            // if(!this.isExistMaterial) {
            //   this.showVideoControl()
            // }
            this.showCountdown()
          })
      } catch (error) {
        const err: any = error
        console.log(err.response.data.errors[0])
        if (err.response.data.errors[0].message == 'Level belum dibeli') {
          window.location.assign('/classroom/' + this.classroomCode)
        } else if (err.response.data.errors[0].message == 'record not found') {
          window.location.assign('/')
        } else {
          window.location.reload()
        }
      }
    },

    async checkUserPrakerja() {
      const { data } = await VoucherModel.isUserPrakerja(this.classroomCode)
      if (data.is_prakerja) {
        this.isPrakerja = true
      }
      // console.log('prakerja', this.isPrakerja)
    },

    async checkIsExistPreTest() {
      await ClassroomModel.getPreTest(this.classroomCode)
        .then(res => {
          this.isExistPreTest = true
          if (res.data.is_done == false) {
            this.testType = 0
            this.prePostTestData = res.data
            this.isDonePreTest = false
            this.isAutoPlay = false
            this.payloadQuiz.item_ids = []
            this.payloadQuiz.answers = []
            this.questionIndex = 0
            for (let i = 0; i < res.data?.items?.length; i++) {
              this.payloadQuiz.item_ids.push(this.prePostTestData.items[i].id)
              this.payloadQuiz.answers.push(null)
            }
          }
        })
        .catch(err => {
          if (
            err.response.data.errors[0].message == 'Sudah mengerjakan Pre Test'
          ) {
            this.isDonePreTest = true
            this.isExistPreTest = true
          }
          if (err.response.data.errors[0].message == 'Kelas belum dibeli') {
            this.$router.push('/classroom/' + this.classroomCode)
          }
        })
    },

    openModalPreTest() {
      if (this.isDonePreTest) {
        this.showModalNotDoneQuiz = true
        this.modalQuiz.imageUrl =
          '/assets/images/modal/illustration-test-completed.svg'
        this.modalQuiz.title = 'Pre-test sudah dikerjakan'
        this.modalQuiz.textOne = 'Kamu sudah mengerjakan pre-test'
      } else {
        this.showModalStartPreTest = true
      }
    },

    openPreTest() {
      this.showModalStartPreTest = false
      this.showModalStartPostTest = false
      this.showPrePost = true
    },

    closeModalStartPrePostTest() {
      this.showModalStartPreTest = false
      this.showModalStartPostTest = false
    },

    async checkIsExistPostTest() {
      await ClassroomModel.getPostTest(this.classroomCode)
        .then(res => {
          if (res.success) {
            this.postTestClass = res.data
            this.isExistPostTest = true
            if (res.data.is_done == false) {
              this.isDonePostTest = false
            }
            if (res.data.is_attempt) {
              this.isAttemptPostTest = true
            }
          }
        })
        .catch(err => {
          if (
            err.response.data.errors[0].message ==
            'Harap kerjakan Pre Test terlebih dahulu'
          ) {
            this.isDonePreTest = false
            this.isDonePostTest = false
            this.isExistPostTest = true
          }
          if (
            err.response.data.errors[0].message ==
            'Tidak Ada Post Test'
          ) {
            this.isExistPostTest = false
            this.isDonePostTest = true
          }
          // console.error(err)
        })
    },

    openModalPostTest() {
      if (
        this.classroomLevel.certificate != '' &&
        this.classroomLevel.is_level_done
      ) {
        this.payloadQuiz.item_ids = []
        this.payloadQuiz.answers = []
        this.questionIndex = 0
        for (let i = 0; i < this.postTestClass?.items?.length; i++) {
          this.payloadQuiz.item_ids.push(this.postTestClass.items[i].id)
          this.payloadQuiz.answers.push(null)
        }
        this.showModalStartPostTest = true
        this.modalQuiz.textTwo =
          'Nilai kelulusan: ' + this.postTestClass?.min_score
      } else {
        this.checkClassroomProgress()
        if (
          this.isVideoDone &&
          this.isModuleDone &&
          this.isQuizDone &&
          this.isWebinarDone &&
          this.isSubmissionDone &&
          this.isExamDone
        ) {
          this.payloadQuiz.item_ids = []
          this.payloadQuiz.answers = []
          this.questionIndex = 0
          for (let i = 0; i < this.postTestClass?.items?.length; i++) {
            this.payloadQuiz.item_ids.push(this.postTestClass.items[i].id)
            this.payloadQuiz.answers.push(null)
          }
          this.showModalStartPostTest = true
          this.modalQuiz.textTwo =
            'Nilai kelulusan: ' + this.postTestClass?.min_score
        } else {
          this.modalQuiz.imageUrl =
            '/assets/images/modal/illustration-test-not-completed.svg'
          this.modalQuiz.title = 'Post-test belum bisa dikerjakan'
          let text = 'Kamu harus menyelesaikan '
          if (this.isExistPreTest && !this.isDonePreTest) {
            text = text + ' Pre-Test,'
          }
          if (!this.isVideoDone) {
            text = text + ' Video,'
          }
          if (!this.isModuleDone) {
            text = text + ' Modul,'
          }
          if (!this.isQuizDone) {
            text = text + ' Kuis,'
          }
          if (!this.isExamDone) {
            text = text + ' Ujian,'
          }
          if (!this.isWebinarDone) {
            text = text + ' Webinar,'
          }
          if (!this.isSubmissionDone) {
            text = text + ' Tugas,'
          }
          text = text.slice(0, -1)
          this.modalQuiz.textOne = text + ' terlebih dahulu'
          this.showModalNotDoneQuiz = true
        }
      }
    },

    openPostTestPage() {
      if (!this.isDonePreTest) {
        this.modalQuiz.imageUrl =
          '/assets/images/modal/illustration-test-not-completed.svg'
        this.modalQuiz.title = 'Post-test belum bisa dikerjakan'
        this.modalQuiz.textOne =
          'Kamu harus mengerjakan pre-test dan materi terlebih dahulu'
        this.showModalNotDoneQuiz = true
      } else {
        this.showPostTest = true
        this.selectedMaterial = {}
        // this.selectedMaterialItem = {}
      }
    },

    openLastMaterial() {
      let material = this.materials[this.materials?.length - 1]
      let materialItem = material.material[material.material?.length - 1]
      this.openMaterial(material, materialItem)
    },

    openPostTest() {
      this.showModalStartPostTest = false
      this.testType = 1
      this.prePostTestData = this.postTestClass
      this.showPrePost = true
    },

    showVideoControl() {
      const el = document.getElementById('play')
      if (
        this.classroomLevel.classroom?.is_must_subject ||
        this.classroomLevel.classroom?.is_must_module ||
        this.classroomLevel.classroom?.is_must_quiz ||
        this.classroomLevel.classroom?.is_must_webinar ||
        this.classroomLevel.classroom?.is_must_webinar_record ||
        this.classroomLevel.classroom?.is_must_exam
      ) {
        this.isNoAccomplishement = false
        el?.children[0].children[0].children[1].setAttribute(
          'style',
          'pointer-events:none;'
        )
      } else {
        this.isNoAccomplishement = true
      }
    },

    openVideoControl() {
      const el = document.getElementById('play')
      el?.children[0].children[0].children[1].removeAttribute('style')
      this.options.keyboard.focused = true
      this.options.keyboard.global = true
      this.options.disableContextMenu = true
    },

    closeVideoControl() {
      const el = document.getElementById('play')
      el?.children[0].children[0].children[1].setAttribute(
        'style',
        'pointer-events:none;'
      )
      this.options.keyboard.focused = false
      this.options.keyboard.global = false
      this.options.disableContextMenu = false
    },

    showCountdown() {
      if (this.isCountdown === 'false') {
        this.isProcessed = true
        this.isStartedYet = true
        this.isDonePreTest = true
      } else {
        const startedAt = this.classroomLevel?.classroom?.started_at
        if (startedAt) {
          const now = new Date()
          const end = new Date(startedAt)
          const distance = end.getTime() - now.getTime()
          if (distance < 0) {
            this.isProcessed = true
            this.isStartedYet = true
          } else {
            this.isAutoPlay = false
            this.isProcessed = true
            const timer = setInterval(() => {
              const now = new Date()
              const end = new Date(startedAt)
              const distance = end.getTime() - now.getTime()
              if (distance < 0) {
                clearInterval(timer)
                this.isStartedYet = true
                return
              }
              this.isAutoPlay = false
              const days = Math.floor(distance / this._days)
              const hours = Math.floor((distance % this._days) / this._hours)
              const minutes = Math.floor(
                (distance % this._hours) / this._minutes
              )
              const seconds = Math.floor(
                (distance % this._minutes) / this._seconds
              )
              if (days <= 0) {
                this.distanceDay = false
                this.displaySeconds = seconds < 10 ? 0 + seconds : seconds
              } else {
                this.distanceDay = true
                this.displayDays = days < 10 ? 0 + days : days
              }
              this.displayMinutes = minutes < 10 ? 0 + minutes : minutes
              this.displayHours = hours < 10 ? 0 + hours : hours
            }, 1000)
          }
        } else {
          this.isProcessed = true
          this.isStartedYet = true
        }
      }
    },

    async getTaskList() {
      const { data } = await ClassroomModel.getTaskClassroom(
        this.classroomLevelCode
      )

      this.tasks = data
      // console.log('daftar tugas', this.tasks)
    },

    closeAlertTab() {
      this.showAlertTab = false
    },

    changeTab() {
      this.showPrePost = false
      this.showQuiz = false
      this.showWebinarQuiz = false
      this.openTab(this.tempTab)
    },

    async openTab(tab: String) {
      this.isLoading = false
      if (!this.isDonePreTest && tab != 'materi') {
        this.$router.push('/classroom/' + this.classroomCode + '/activity')
        this.showAlert = true
        this.modalAlert.status = 'error'
        this.modalAlert.title = 'Akses Dibatasi'
        this.modalAlert.description =
          'Kerjakan Pre-Test dahulu untuk bisa mengakses materi yang ada, Semangat!'
        this.tab = 'materi'
        return
      }
      if (this.showPrePost || this.showQuiz || this.showWebinarQuiz) {
        this.tempTab = tab
        if (this.showQuiz) {
          this.modalQuiz.title = 'Maaf, kuis kamu belum selesai'
          this.modalQuiz.textOne =
            'Apakah kamu yakin ingin meninggalkan kuis ini?'
        } else if (this.showPrePost) {
          if (this.testType) {
            this.modalQuiz.title = 'Maaf, post-test kamu belum selesai'
            this.modalQuiz.textOne =
              'Apakah kamu yakin ingin meninggalkan post-test ini?'
          } else {
            this.modalQuiz.title = 'Maaf, pre-test kamu belum selesai'
            this.modalQuiz.textOne =
              'Apakah kamu yakin ingin meninggalkan pre-test ini?'
          }
        } else if (this.showWebinarQuiz) {
          if (this.webinarQuizData?.quiz_type) {
            this.modalQuiz.title = 'Maaf, post-test webinar kamu belum selesai'
            this.modalQuiz.textOne =
              'Apakah kamu yakin ingin meninggalkan post-test webinar ini?'
          } else {
            this.modalQuiz.title = 'Maaf, pre-test webinar kamu belum selesai'
            this.modalQuiz.textOne =
              'Apakah kamu yakin ingin meninggalkan pre-test webinar ini?'
          }
        }
        this.showAlertTab = true
      } else {
        if (tab !== 'materi') {
          const file = document.querySelector('#vplyr') as HTMLVideoElement
          if (file != null) {
            file.pause()
          }
        }
        this.showAlertTab = false
        this.isLoadingWebinar = true
        this.isLoadingMaterial = true
        this.tab = tab
        // Refactor, if data of tab info empty then call vuex action
        if (
          tab === 'webinar' &&
          this.classroomLevel.classroom.must_webinar_tab
        ) {
          this.$router.push(
            '/classroom/' + this.classroomCode + '/activity?tab=webinar'
          )
          if (this.webinarRecord?.length == 0) {
            this.getWebinarRecord()
          }
          await this.getWebinarQuiz()
          const {
            'classroomStore/getClassroomWebinarData': getClassroomWebinarData,
          } = this.$store.getters as any

          if (getClassroomWebinarData?.length == 0) {
            this.getListWebinar()
          }
        } else if (
          tab === 'task' &&
          this.classroomLevel.classroom.must_submission_tab
        ) {
          this.$router.push(
            '/classroom/' + this.classroomCode + '/activity?tab=task'
          )
          if (this.tasks?.length == 0) {
            this.getTaskList()
          }
        } else if (tab === 'ask-mentor') {
          this.$router.push(
            '/classroom/' + this.classroomCode + '/activity?tab=ask-mentor'
          )
          if (this.listUserQuestion?.length == 0) {
            this.getClassroomQuestion()
          }
        } else if (
          tab === 'forum' &&
          this.isExistForum
        ) {
          this.$router.push(
            '/classroom/' + this.classroomCode + '/activity?tab=forum'
          )
        } else if (
          tab === 'konsultasi-desain' &&
          this.classroomLevel.classroom.is_webinar_private
        ) {
          this.$router.push(
            '/classroom/' + this.classroomCode + '/activity?tab=konsultasi-desain'
          )
          this.getPrivateWebinar()
        } else {
          if (
            this.classroomLevel?.classroom?.must_subject_tab ||
            (this.classroomLevel?.classroom?.must_module_tab &&
              this.classroomLevel?.classroom?.must_quizzes_tab)
          ) {
            this.tab = 'materi'
            this.$router.push('/classroom/' + this.classroomCode + '/activity')
            if (this.materials?.length == 0) {
              this.getMaterialsList()
            }
          } else {
            this.tab = 'forum'
            this.$router.push(
              '/classroom/' + this.classroomCode + '/activity?tab=forum'
            )
          }
        }
      }
    },

    async getClassroomQuestion() {
      this.loadingUserQuestion = true
      await ClassroomModel.listQuestion(this.classroomCode)
        .then(res => {
          this.loadingUserQuestion = false
          this.listUserQuestion = res.data.reverse()
        })
        .catch(err => {
          this.loadingUserQuestion = false
          console.log(err)
        })
    },

    openModalAskMentor() {
      this.showModalAskMentor = true
    },

    closeModalAskMentor() {
      this.showModalAskMentor = false
    },

    async submitQuestion() {
      this.loadingSubmitQuestion = true
      let formData = new FormData()
      formData.append('question', this.formQuestion.question)
      if (this.formQuestion.file) {
        formData.append('file', this.formQuestion.file)
      }
      await ClassroomModel.addQuestion(this.classroomCode, formData)
        .then(res => {
          this.loadingSubmitQuestion = false
          if (res.success) {
            this.showModalAskMentor = false
            this.formQuestion.file = ''
            this.formQuestion.question = ''
            const el = this.$refs.questionFile
            if (el) {
              ;(this.$refs.questionFile as any).value = null
            }
            this.toast.success('Pertanyaan berhasil diajukan')
            this.getClassroomQuestion()
          } else {
            this.toast.error('Silahkan coba kembali')
          }
        })
        .catch(err => {
          const error: any = err.response.data.errors[0].message
          this.toast.error(error)
          this.loadingSubmitQuestion = false
        })
    },
    async getQuiz() {
      try {
        const { data } = await ClassroomModel.listQuiz(this.classroomLevelCode)
        this.isLoadingQuiz = false
        const isExamExist = data.some(el => el.is_exam == true)
        if (isExamExist) {
          this.quiz = (data as Quiz[]).sort((a, b) =>
            a.is_exam === b.is_exam ? 0 : a.is_exam ? 1 : -1
          )
        } else {
          this.quiz = data
        }
        const isDoneQuiz = data.filter(el => {
          if (el.is_exam == false && el.is_done == false) {
            return el
          }
        })
        if (isExamExist) {
          if (isDoneQuiz?.length > 0) {
            this.isDoneQuiz = false
          } else {
            this.isDoneQuiz = true
          }
        } else {
          this.isDoneQuiz = true
        }
      } catch (error) {
        this.isLoadingQuiz = false
      }
    },
    async getListWebinar() {
      try {
        this.$store
          .dispatch(
            'classroomStore/fetchClassroomWebinar',
            this.classroomLevelCode
          )
          .then(() => {
            const {
              'classroomStore/getLoadingClassroomWebinarData': getLoadingClassroomWebinarData,
            } = this.$store.getters
            this.isLoadingWebinar = getLoadingClassroomWebinarData
            const {
              'classroomStore/getClassroomWebinarData': getClassroomWebinarData,
            } = this.$store.getters as any
            if (getClassroomWebinarData?.length > 0) {
              this.listRequestWebinarPlus = getClassroomWebinarData.filter(
                (el: any) => {
                  if (
                    el.can_request_plus &&
                    this.timeZone(el.request_plus_started_at) <= this.dateNow &&
                    this.timeZone(el.request_plus_expired_at) >= this.dateNow
                  ) {
                    return el
                  }
                }
              )
            }
            if (this.countWebinarOnGoing > 0) {
              this.changeFilterWebinar('ongoing')
            } else {
              this.changeFilterWebinar(this.filterWebinar)
            }
          })
      } catch (error) {
        this.isLoadingWebinar = false
      }
    },
    async getWebinarRecord() {
      // const { data } = await ClassroomModel.detailClassroomLevel(classroomCode)
      // this.levelData = data
      this.levelData = await this.$store.getters[
        'classroomStore/getDetailClassroom'
      ]
      if (!this.levelData.id) {
        const { data } = await ClassroomModel.detailClassroomLevel(
          this.classroomLevelCode
        )
        this.levelData = data
      }
      try {
        await this.$store
          .dispatch(
            'classroomStore/fetchClassroomWebinarRecord',
            this.levelData.id
          )
          .then(() => {
            const {
              'classroomStore/getLoadingClassroomWebinarRecordData': getLoadingClassroomWebinarRecordData,
              'classroomStore/getClassroomWebinarRecordData': getClassroomWebinarRecordData,
            } = this.$store.getters
            this.isLoadingWebinarRecord = getLoadingClassroomWebinarRecordData
            this.webinarRecord = []
            if (getClassroomWebinarRecordData) {
              getClassroomWebinarRecordData.forEach((el: any, i: number) => {
                if (el.link.includes('iframe')) {
                  this.webinarRecord.push({
                    ...el,
                    type: 'Youtube',
                  })
                } else {
                  this.webinarRecord.push({
                    ...el,
                    type: 'Zoom',
                  })
                }
              })
            }
          })
      } catch (error) {
        this.isLoadingWebinarRecord = false
      }
    },
    // reRender() {
    //   this.init()
    // },
    async onClickWebinar(id: number, startedDate: any, expiredDate: any) {
      const start = this.timeZone(startedDate)
      const end = this.timeZone(expiredDate)
      const exist = this.preTest?.filter((x: any) => x.webinar_id == id)
      if (exist?.length > 0) {
        if (exist[0].is_done === false) {
          this.showAlert = true
          this.modalAlert.status = 'error'
          this.modalAlert.title = 'Gagal'
          this.modalAlert.description =
            'Kamu harus menyelesaikan Pre-Test Webinar dahulu'
          return
        }
      }
      if (this.getDateNow() < start) {
        this.showAlert = true
        this.modalAlert.status = 'error'
        this.modalAlert.title = 'Gagal'
        this.modalAlert.description = 'Webinar belum mulai'
        return
      }
      if (this.getDateNow() > end) {
        this.showAlert = true
        this.modalAlert.status = 'error'
        this.modalAlert.title = 'Gagal'
        this.modalAlert.description = 'Webinar telah berakhir'
        return
      }

      const { data, success } = await ClassroomModel.joinWebinar(id)
      if (success) {
        window.location.assign(data.redirection)
        this.init()
      }
    },
    getDateNow() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    onClickPreTest(data: any) {
      this.webinarQuiz = data
      const startedDate = this.timeZone(data.started_at)
      const endedDate = this.timeZone(data.ended_at)
      if (data?.is_done) {
        this.showAlert = true
        this.modalAlert.status = 'success'
        this.modalAlert.title = 'Berhasil'
        this.modalAlert.description = 'Kamu sudah mengerjakan Pre-Test Webinar'
        return
      }
      if (
        data.started_at == '0001-01-01 00:00:00' ||
        data.started_at == '' ||
        data.started_at == null
      ) {
        this.textModalWebinarQuiz =
          'Pre-test Webinar ini hanya dapat dikerjakan satu kali. Semua soal dalam pre-test akan dibahas oleh mentor dalam webinar.'
        this.modalWebinarQuiz = true
        return
      } else {
        if (startedDate > this.getDateNow()) {
          const formatDate = moment(startedDate)
            .locale('id')
            .format('DD MMMM YYYY HH:mm')

          this.showAlert = true
          this.modalAlert.status = 'error'
          this.modalAlert.title = 'Gagal'
          this.modalAlert.description =
            'Pre-Test Webinar baru bisa diakses: ' + formatDate + ' WIB'
          return
        } else if (endedDate < this.getDateNow()) {
          this.showAlert = true
          this.modalAlert.status = 'error'
          this.modalAlert.title = 'Gagal'
          this.modalAlert.description = 'Waktu Pre-Test Webinar telah berakhir'
          return
        } else {
          this.textModalWebinarQuiz =
            'Pre-test Webinar ini hanya dapat dikerjakan satu kali. Semua soal dalam pre-test akan dibahas oleh mentor dalam webinar.'
          this.modalWebinarQuiz = true
          return
        }
      }
    },
    async onClickPostTest(data: any) {
      this.webinarQuiz = data
      const startedDate = this.timeZone(data.started_at)
      const endedDate = this.timeZone(data.ended_at)
      if (data?.is_done) {
        this.showAlert = true
        this.modalAlert.status = 'success'
        this.modalAlert.title = 'Berhasil'
        this.modalAlert.description = 'Kamu sudah mengerjakan Post-Test Webinar'
        return
      }
      if (this.preTest?.length > 0) {
        const exist = this.preTest?.filter(
          (x: any) => x.webinar_id == data.webinar_id
        )
        if (exist?.length > 0) {
          if (exist[0].is_done === false) {
            this.showAlert = true
            this.modalAlert.status = 'error'
            this.modalAlert.title = 'Gagal'
            this.modalAlert.description =
              'Kamu harus menyelesaikan Pre-Test Webinar dahulu'
            return
          }
        } else {
          if (startedDate > this.getDateNow()) {
            const formatDate = moment(startedDate)
              .locale('id')
              .format('DD MMMM YYYY HH:mm')
            this.showAlert = true
            this.modalAlert.status = 'error'
            this.modalAlert.title = 'Gagal'
            this.modalAlert.description =
              'Post-Test Webinar baru bisa diakses: ' + formatDate + ' WIB'
            return
          } else if (endedDate < this.getDateNow()) {
            this.showAlert = true
            this.modalAlert.status = 'error'
            this.modalAlert.title = 'Gagal'
            this.modalAlert.description =
              'Waktu Post-Test Webinar telah berakhir'
            return
          }
          // else {
          // this.$router.push(
          //   `/classroom/${this.classroomCode}/post-test/${data.id}`
          // )
          // }

          // if (this.classroomLevel?.is_webinar_done) {
          // } else {
          //   this.showAlert = true
          //   this.modalAlert.status = 'error'
          //   this.modalAlert.title = 'Gagal'
          //   this.modalAlert.description = 'Kamu harus mengikuti webinar dahulu'
          //   return
          // }
        }
      }
      this.payloadWebinarQuiz.item_ids = []
      this.payloadWebinarQuiz.answers = []
      this.isDoneWebinarQuiz = false
      this.questionIndex = 0
      await WebinarQuizModel.getWebinarQuizV2(this.webinarQuiz.id).then(res => {
        this.webinarQuizData = res.data
        for (let i = 0; i < this.webinarQuizData?.items?.length; i++) {
          this.payloadWebinarQuiz.item_ids.push(
            this.webinarQuizData.items[i].id
          )
          this.payloadWebinarQuiz.answers.push(null)
        }
        this.showWebinarQuiz = true
      })
    },
    async showWebinarPreTest() {
      this.payloadWebinarQuiz.item_ids = []
      this.payloadWebinarQuiz.answers = []
      this.isDoneWebinarQuiz = false
      this.questionIndex = 0
      const { data } = await WebinarQuizModel.getWebinarQuizV2(
        this.webinarQuiz.id
      )
      this.webinarQuizData = data
      for (let i = 0; i < this.webinarQuizData?.items?.length; i++) {
        this.payloadWebinarQuiz.item_ids.push(this.webinarQuizData.items[i].id)
        this.payloadWebinarQuiz.answers.push(null)
      }
      this.showWebinarQuiz = true
      this.modalWebinarQuiz = false
    },
    hideModalWebinarQuiz() {
      this.modalWebinarQuiz = false
      this.webinarQuiz = {} as WebinarQuiz
    },
    formatDuration(duration: string) {
      return formatDuration(duration)
    },

    settingQuality(data: any) {
      if (data?.resolutions?.length > 0) {
        this.isExistResolution = true
        // const quality = [] as any
        // data.resolutions.forEach((el: any) => {
        //   const res = parseInt(el.resolution)
        //   quality.push(res)
        // })
        // this.options.quality.default = quality[0]
        // this.options.quality.options = [360, 480, 720, 1080]
        // this.options.settings = ['quality']
        const el = document.getElementById('play')
        el?.children[0].children[0].children[4].removeAttribute('style')
      } else {
        this.isExistResolution = false
        // this.options.quality.options = []
        // this.options.settings = []
        const el = document.getElementById('play')
        el?.children[0].children[0].children[4].setAttribute(
          'style',
          'display:none!important'
        )
      }
      // console.log(this.options)
    },

    hideModalPack() {
      // console.log('close')
      this.modalPackStatusError = false
    },

    async getWebinarQuiz() {
      const { data, success } = await ClassroomModel.listWebinarQuiz(
        this.classroomLevelCode
      )
      if (success) {
        const get = data.filter(
          (el: any) => el.quiz_type === 0 && el.is_done === true
        )
        this.preTest = data
          .sort((a, b) => a.ordering - b.ordering)
          .filter((el: any) => el.quiz_type === 0)
        // this.postTest = data.filter((el: any) => el.quiz_type === 1)

        this.postTest = data
          .sort((a, b) => a.ordering - b.ordering)
          .filter((el: any) => el.quiz_type === 1)
        // console.log('pre test', this.preTest)
        // console.log('post test', this.postTest)
      }
    },
    timeZone(date: string) {
      const dt = new Date(date)
      const offset = new Date().getTimezoneOffset()
      const timeZoneOffset = offset / 60

      switch (timeZoneOffset) {
        case -8:
          dt.setHours(dt.getHours() + 1)
          break
        case -9:
          dt.setHours(dt.getHours() + 2)
          break
        default:
          break
      }
      return moment(dt).format('YYYY-MM-DD HH:mm:ss')
    },

    onClickRequestWebinarPlus(
      id: number,
      start: string,
      end: string,
      classroom: string
    ) {
      this.payloadRequestWebinarPlus.classroom = classroom
      const now = moment().format('YYYY-MM-DD HH:mm:ss')
      if (this.timeZone(start) <= now && this.timeZone(end) >= now) {
        this.payloadRequestWebinarPlus.id = id
        this.modalRequestWebinarPlus = true
      } else {
        const startedAt = moment(start)
          .locale('id')
          .format('DD MMMM YYYY HH:mm')
        this.showAlert = true
        this.modalAlert.status = 'error'
        this.modalAlert.title = 'Forbidden'
        this.modalAlert.description =
          'Pengajuan Webinar Plus bisa diakses pada ' + startedAt
      }
    },

    showWebinarPlusModal() {
      this.modalRequestWebinarPlus = true
    },

    closeWebinarPlusModal() {
      this.modalRequestWebinarPlus = false
      this.payloadRequestWebinarPlus.id = 0
      this.payloadRequestWebinarPlus.reason = ''
      // this.showWebinarPlusReason = false
    },

    // nextWebinarPlusModal() {
    //   this.showWebinarPlusReason = true
    // },

    async submitRequestWebinarPlus() {
      const payload = {
        reason: this.payloadRequestWebinarPlus.reason,
      }
      await ClassroomModel.requestWebinarPlusUser(
        this.payloadRequestWebinarPlus.id,
        payload
      )
        .then(res => {
          this.showAlert = true
          if (res.success) {
            this.closeWebinarPlusModal()
            this.modalAlert.status = 'success'
            this.modalAlert.title = 'Berhasil'
            this.modalAlert.description =
              'Anda berhasil mengirim Request Webinar Plus'
          } else {
            this.modalAlert.status = 'error'
            this.modalAlert.title = 'Gagal'
            this.modalAlert.description = 'Terjadi Kesalahan'
          }
        })
        .catch(err => {
          this.closeWebinarPlusModal()
          this.showAlert = true
          this.modalAlert.status = 'error'
          this.modalAlert.title = 'Gagal'
          this.modalAlert.description = err.response.data.errors[0].message
        })
    },

    checkTask(start: any) {
      const now = new Date()
      const startAt = new Date(start)
      if (now.getTime() >= startAt.getTime()) {
        return false
      } else {
        return true
      }
    },

    moreQuestion() {
      this.isExpandQuestion = !this.isExpandQuestion
    },

    downloadLink(pdf: string) {
      axios
        .get(pdf, {
          responseType: 'blob',
          headers: {
            'Content-type': 'application/pdf',
          },
        })
        .then(response => {
          const blob = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = blob
          const filename = pdf.substring(pdf.lastIndexOf('/') + 1)
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
        })
        .catch(() => {
          window.open(pdf, '_blank')
        })
    },

    openModalReview() {
      if (
        this.classroomLevel.certificate != '' &&
        this.classroomLevel.is_level_done &&
        this.isDonePostTest
      ) {
        if (this.classroomLevel.rating > 3) {
          this.isAlreadyRated = true
        } else {
          this.isAlreadyRated = false
        }
        this.formReview.rating = this.classroomLevel.rating
        this.tempRating = this.classroomLevel.rating
        this.formReview.content = this.classroomLevel.content
        if (this.classroomLevel.image_rating) {
          this.updatedImageRating = this.classroomLevel.image_rating
        }
        this.previewImageRating = null
        this.showReview = true
      } else {
        this.checkClassroomProgress()
        if (
          this.isVideoDone &&
          this.isModuleDone &&
          this.isQuizDone &&
          this.isWebinarDone &&
          this.isSubmissionDone &&
          this.isExamDone &&
          !this.classroomLevel?.is_locked &&
          this.isStartedYet &&
          this.isDonePostTest
        ) {
          if (this.classroomLevel.rating > 3) {
            this.isAlreadyRated = true
          } else {
            this.isAlreadyRated = false
          }
          this.formReview.rating = this.classroomLevel.rating
          this.tempRating = this.classroomLevel.rating
          this.formReview.content = this.classroomLevel.content
          if (this.classroomLevel.image_rating) {
            this.updatedImageRating = this.classroomLevel.image_rating
          }
          this.showReview = true
        } else {
          this.showModalNotDoneQuiz = true
          this.modalQuiz.imageUrl =
            '/assets/images/modal/illustration-attention.svg'
          this.modalQuiz.title = 'Kelas Belum Selesai'
          let text = 'Yuk selesikan '
          if (this.isExistPreTest && !this.isDonePreTest) {
            text = text + ' Pre-Test,'
          }
          if (!this.isVideoDone) {
            text = text + ' Video,'
          }
          if (!this.isModuleDone) {
            text = text + ' Modul,'
          }
          if (!this.isQuizDone) {
            text = text + ' Kuis,'
          }
          if (!this.isExamDone) {
            text = text + ' Ujian,'
          }
          if (!this.isWebinarDone) {
            text = text + ' Webinar,'
          }
          if (!this.isSubmissionDone) {
            text = text + ' Tugas,'
          }
          if (this.isExistPostTest && !this.isDonePostTest) {
            text = text + ' Post-Test,'
          }
          text = text.slice(0, -1)
          this.modalQuiz.textOne = text + ' dulu ya, Semangat!'
        }
      }
    },

    closeModalReview() {
      if (!this.loadingReview) {
        this.showReview = false
      }
    },

    async submitReview() {
      this.loadingReview = true
      await ClassroomModel.GiveReviewClassroomLevelV2(
        this.classroomLevelCode,
        this.formReview
      )
        .then(res => {
          this.loadingReview = false
          if (res.success) {
            this.toast.success('Review berhasil terkirim')
            this.getClassRoomLevelDetail(this.classroomLevelCode)
            this.showReview = false
          } else {
            this.toast.error('Terjadi Kesalahan, silahkan coba kembali.')
          }
        })
        .catch(err => {
          this.loadingReview = false
          this.toast.error('Review gagal terkirim')
          console.error(err)
        })
    },

    toggleWebinar() {
      this.showWebinarList = !this.showWebinarList
    },

    changeFilterWebinar(filter: string) {
      if (!this.showWebinarQuiz) {
        this.showWebinar = true
        this.showWebinarList = true
        this.showWebinarRecord = false
        this.showWebinarRecordList = false
        this.filterWebinar = filter
        this.selectedWebinarRecord = null
        const {
          'classroomStore/getClassroomWebinarData': getClassroomWebinarData,
        } = this.$store.getters as any
        const dateNow = moment().format('YYYY-MM-DD HH:mm:ss')
        if (getClassroomWebinarData?.length > 0) {
          this.listWebinar = getClassroomWebinarData.filter((el: any) => {
            if (filter == 'done') {
              if (this.timeZone(el.expired_at) < dateNow) {
                return el
              }
            } else if (filter == 'ongoing') {
              if (
                this.timeZone(el.started_at) <= dateNow &&
                this.timeZone(el.expired_at) >= dateNow
              ) {
                return el
              }
            } else {
              if (this.timeZone(el.started_at) > dateNow) {
                return el
              }
            }
          })
        }
      }
    },

    toggleWebinarRecord() {
      this.showWebinarRecordList = !this.showWebinarRecordList
    },

    checkRegexIframe(link: string) {
      if (link.includes('<iframe')) {
        return true
      } else {
        return false
      }
    },

    async openWebinarRecord(record: any) {
      if (record.is_viewed == false) {
        if (!this.getProfileData) {
          await this.getProfile()
        }
        await ClassroomModel.viewWebinarRecord(
          record.id,
          this.getProfileData.uuid
        )
        await this.getWebinarRecord()
        this.checkClassroomProgress()
      }
      this.showWebinar = false
      this.showWebinarList = false
      this.showWebinarRecord = true
      this.showWebinarRecordList = true
      this.selectedWebinarRecord = record
      if (this.selectedWebinarRecord?.link?.includes('iframe')) {
        var parser: any = new DOMParser()
        var parsedIframe = parser.parseFromString(
          this.selectedWebinarRecord?.link,
          'text/html'
        )
        let iFrame = parsedIframe.getElementsByTagName('iframe')
        this.selectedWebinarRecord.url = iFrame[0].src
      }
    },

    webinarTimeFormatted(start: string, end: string) {
      const startDate = moment(start)
        .locale('id')
        .format('DD MMMM YYYY HH:mm')
      const endDate = moment(end)
        .locale('id')
        .format('HH:mm')
      return startDate + ' - ' + endDate + ' WIB'
    },

    copyPasswordWebinar() {
      const password = this.$refs.password as HTMLInputElement
      try {
        password.setAttribute('type', 'text')
        password.select()
        document.execCommand('copy')
        this.toast.success('Berhasil disalin')
      } catch (err) {
        this.toast.error('Oops, unable to copy')
      }
      password.setAttribute('type', 'hidden')
    },

    closeShowAlert() {
      this.showAlert = false
    },

    roundingScore(score: number) {
      return Math.round(score)
    },

    openTask(task: any) {
      this.selectedTask = task
      if (task.file != '') {
        if (task.note == '') {
          this.formTask.note = '<tidak memberikan keterangan>'
        } else {
          this.formTask.note = task.note
        }
      }
      this.showModalTask = true
    },

    closeTask() {
      this.selectedTask = {}
      this.showModalTask = false
    },

    openModalTaskScore() {
      this.showTaskScore = true
    },

    closeModalTaskScore() {
      this.showTaskScore = false
    },

    removeTaskFile() {
      this.formTask.file = ''
    },
    dragoverTask(event: any) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-[#E8EDFF]')) {
        event.currentTarget.classList.remove('bg-[#FFFFFF]')
        event.currentTarget.classList.add('bg-[#E8EDFF]')
      }
    },
    dragleaveTask(event: any) {
      // Clean up
      event.currentTarget.classList.add('bg-[#FFFFFF]')
      event.currentTarget.classList.remove('bg-[#E8EDFF]')
    },
    dropTask(event: any) {
      event.preventDefault()
      ;(this.$refs.taskFile as any).files = event.dataTransfer.files
      this.formTask.file = event.dataTransfer.files[0]
      // Clean up
      event.currentTarget.classList.add('bg-[#FFFFFF]')
      event.currentTarget.classList.remove('bg-[#E8EDFF]')
    },

    async openModalAddQuota() {
      if (!this.listQuotaPrice?.price) {
        await TransactionModel.getQuotaPrice()
          .then(res => {
            this.listQuotaPrice = res.data
          })
          .catch(err => {
            this.toast.error(err.response.data.errors[0].message)
          })
      }
      if (this.packageTopup?.length == 0) {
        await TransactionModel.getListWebinarPackage()
          .then(res => {
            this.packageTopup = res.data
          })
          .catch(err => {
            this.toast.error(err.response.data.errors[0].message)
          })
      }
      this.formAddQuota.classroom_code = this.classroomCode
      this.showModalAddQuota = true
    },

    closeModalAddQuota() {
      this.showModalAddQuota = false
      this.displayPaymentQuota = false
      this.formAddQuota.amount = 1
      this.formAddQuota.payment_code = ''
    },

    minQuota() {
      this.formAddQuota.amount = this.formAddQuota.amount - 1
    },

    plusQuota() {
      this.formAddQuota.amount = this.formAddQuota.amount + 1
    },

    selectPayment(data: any) {
      this.formAddQuota.payment_code = data.code
    },

    async submitAddQuota() {
      this.loadingAddQuota = true
      let payload: any = {
        code: this.selectedPackage.code,
        amount: this.formAddQuota.amount,
        payment_code: this.formAddQuota.payment_code,
        classroom_code: this.formAddQuota.classroom_code,
      }
      if (this.usePackageTopup) {
        delete payload.amount
        await TransactionModel.addTransactionForPackage(payload)
          .then(res => {
            this.loadingAddQuota = false
            this.$router.push('/payment/' + res.data.code)
          })
          .catch(err => {
            this.loadingAddQuota = false
            this.toast.error(err.response.data.errors[0].message)
          })
      } else {
        delete payload.code
        await TransactionModel.addTransactionForQuota(payload)
          .then(res => {
            this.loadingAddQuota = false
            this.$router.push('/payment/' + res.data.code)
          })
          .catch(err => {
            this.loadingAddQuota = false
            this.toast.error(err.response.data.errors[0].message)
          })
      }
    },

    async getPrivateWebinar() {
      if (Object.keys(this.classroomLevel)?.length === 0) {
        await this.getClassRoomLevelDetail(this.classroomLevelCode)
      }
      if (this.materials?.length == 0) {
        await this.getMaterialsList()
      }

      await this.$store
        .dispatch(
          'classroomStore/fetchClassroomPrivateWebinar',
          this.classroomCode
        )
        .then(() => {
          if (this.countPrivateWebinarApproved > 0) {
            this.changeFilterPrivateWebinar('approved')
          } else {
            this.changeFilterPrivateWebinar(this.filterPrivateWebinar)
          }
        })
    },

    changeFilterPrivateWebinar(filter: string) {
      this.filterPrivateWebinar = filter
      const {
        'classroomStore/getClassroomPrivateWebinarData': getClassroomPrivateWebinarData,
      } = this.$store.getters as any
      if (getClassroomPrivateWebinarData?.length > 0) {
        this.privateWebinar = getClassroomPrivateWebinarData.filter(
          (el: any) => {
            if (filter == 'requested') {
              if (el.status == 0) {
                return el
              }
            } else if (filter == 'approved') {
              if (el.status == 1) {
                return el
              }
            } else if (filter == 'rejected') {
              if (el.status == -1) {
                return el
              }
            } else {
              return el
            }
          }
        )
      }
    },

    openModalPrivateWebinar() {
      // const popover: any = document.getElementsByClassName('v-popper--theme-webinar').item(0)
      // console.log(popover)
      // this.showAlertPrivateWebinar = true
      this.showModalPrivateWebinar = true
      this.isReapplyPrivateWebinar = false
      this.formPrivateWebinar.material = ''
      this.formPrivateWebinar.note = ''
      this.formPrivateWebinar.mentor = ''
      this.formPrivateWebinar.request_started_at = ''
    },

    closeModalPrivateWebinar() {
      this.showModalPrivateWebinar = false
      this.isReapplyPrivateWebinar = false
      this.formPrivateWebinar.material = ''
      this.formPrivateWebinar.note = ''
      this.formPrivateWebinar.mentor = ''
      this.formPrivateWebinar.request_started_at = ''
    },

    async submitAddPrivateWebinar() {
      this.loadingAddPrivateWebinar = true
      const payload = {
        request_started_at: moment(
          this.formPrivateWebinar.request_started_at
        ).format('YYYY-MM-DD HH:mm:00'),
        material: this.formPrivateWebinar.material,
        note: this.formPrivateWebinar.note,
      }
      // console.log(payload)
      await ClassroomModel.addPrivateWebinar(this.classroomCode, payload)
        .then(res => {
          this.loadingAddPrivateWebinar = false
          if (res.success) {
            this.showAlert = true
            this.modalAlert.status = 'success'
            this.modalAlert.title = 'Berhasil'
            this.modalAlert.description =
              'Pengajuan konsultasi desain terkirim, Silahkan hubungi Admin.'
          } else {
            this.showAlert = true
            this.modalAlert.status = 'error'
            this.modalAlert.title = 'Gagal'
            this.modalAlert.description =
              'Terjadi kesalahan, silahkan coba kembali.'
          }
          this.getClassRoomLevelDetail(this.classroomLevelCode)
          this.getPrivateWebinar()
          this.closeModalPrivateWebinar()
        })
        .catch(err => {
          this.loadingAddPrivateWebinar = false
          this.showAlert = true
          this.modalAlert.status = 'error'
          this.modalAlert.title = 'Gagal'
          this.modalAlert.description = err.response.data.errors[0].message
        })
    },

    changePrivateWebinarMaterial() {
      console.log(this.formPrivateWebinar.material)
    },

    openModalReapplyPrivateWebinar(data: any) {
      this.selectedPrivateWebinar = data
      this.isReapplyPrivateWebinar = true
      this.showModalPrivateWebinar = true
      const noteSplit = data.note.split(/\r\n|\n\r|\n|\r/)
      this.formPrivateWebinar.material = noteSplit[0]
      this.formPrivateWebinar.note = noteSplit[1]
      this.formPrivateWebinar.mentor = this.classroomLevel.classroom.mentor_data[0].name
    },

    async submitReapplyPrivateWebinar() {
      this.loadingAddPrivateWebinar = true
      const payload = {
        request_started_at: moment(
          this.formPrivateWebinar.request_started_at
        ).format('YYYY-MM-DD HH:mm:00'),
      }
      // console.log(this.selectedPrivateWebinar)
      // console.log(payload)
      await ClassroomModel.reapplyPrivateWebinar(
        this.selectedPrivateWebinar.id,
        payload
      )
        .then(res => {
          this.loadingAddPrivateWebinar = false
          if (res.success) {
            this.getClassRoomLevelDetail(this.classroomLevelCode)
            this.getPrivateWebinar()
            this.closeModalPrivateWebinar()
            this.showAlert = true
            this.modalAlert.status = 'success'
            this.modalAlert.title = 'Berhasil'
            this.modalAlert.description =
              'Pengajuan ulang konsultasi desain terkirim, Silahkan hubungi Admin.'
          } else {
            this.showAlert = true
            this.modalAlert.status = 'error'
            this.modalAlert.title = 'Gagal'
            this.modalAlert.description =
              'Terjadi kesalahan, silahkan coba kembali.'
          }
        })
        .catch(err => {
          this.loadingAddPrivateWebinar = false
          this.showAlert = true
          this.modalAlert.status = 'error'
          this.modalAlert.title = 'Gagal'
          this.modalAlert.description = err.response.data.errors[0].message
        })
    },

    async joinPrivateWebinar(link: string, startedDate: any, expiredDate: any) {
      const start = this.timeZone(startedDate)
      const end = this.timeZone(expiredDate)
      if (this.getDateNow() < start) {
        this.showAlert = true
        this.modalAlert.status = 'error'
        this.modalAlert.title = 'Gagal'
        this.modalAlert.description = 'Konsultasi belum mulai'
        return
      }
      if (this.getDateNow() > end) {
        this.showAlert = true
        this.modalAlert.status = 'error'
        this.modalAlert.title = 'Gagal'
        this.modalAlert.description = 'Konsultasi telah berakhir'
        return
      }

      window.location.assign(link)
    },

    backChooseQuota() {
      this.usePackageTopup = false
      this.selectedPackage = {} as PackageTopup
      this.displayPaymentQuota = false
    },

    choosePackage(data: PackageTopup) {
      this.usePackageTopup = true
      this.selectedPackage = data
      this.displayPaymentQuota = true
    },

    formatPrice(price: string) {
      return price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    },

    checkIsAssetFile(file: string) {
      if (file.includes('assets')) {
        return true
      } else {
        return false
      }
    },

    dateFormatLong(date: string) {
      return moment(date)
        .locale('id')
        .format('dddd, DD MMMM YYYY')
    },

    async onFileUpload(event: any) {
      const size = event.target.files[0].size
      if (size > 2097152) {
        this.toast.error('Ukuran foto maksimal 2Mb')
        return
      }
      const fileName = event.target.files[0].name.substr(-3).toLowerCase()
      if (['png', 'jpg', 'peg'].indexOf(fileName) <= -1) {
        this.toast.error('Format file tidak sesuai')
        return
      }
      this.formReview.imageRating = event.target.files[0]
      this.previewImageRating = URL.createObjectURL(event.target.files[0])
      if (this.previewImageRating) {
        this.showModalImageRating = true
      }
    },

    closeModalImage() {
      this.previewImageRating = null
      this.formReview.imageRating = null
      this.showModalImageRating = false
      const input: any = this.$refs.imageRating
      input.value = null;
    },

    cropImage() {
      this.loadingCropImage = true
      const { canvas } = (this.$refs.cropper as any).getResult()
      canvas.toBlob((blob: any) => {
        this.loadingCropImage = false
        // blob.name = this.file.name
        // blob.lastModifiedDate = new Date()
        const image = new File([blob], blob.name, { type: 'image/png' })
        this.updatedImageRating = URL.createObjectURL(image)
        this.formReview.imageRating = image
        this.showModalImageRating = false
      })
    },
  },

  components: {
    Webinar,
    CardWebinar,
    CardPrivateWebinar,
    ModalPack,
    ModalRequestWebinar,
    ModalQuiz,
    ModalKuis,
    ModalResultQuiz,
    ModalResultPreTest,
    ModalResultPostTest,
    ModalStatus,
    Slider,
    Loading,
  },
})

